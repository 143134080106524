import React, { useState, useEffect } from "react";
import Logincss from "./login.module.css";
import { useParams } from "react-router-dom";
import http from "../../http-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { LatoFont } from "../../fonts";
import RedirectWithDelay from './redirect';
import { useNavigate } from "react-router-dom";

function VerifiedEmail() {
  let { id } = useParams();
  const [verified, setVerified] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    if (!navigator.onLine) {
      navigate("/offline");
    }
    else{
      http.put(`users/verify/${id}`).then((response) => {
        setVerified(true);
      });
    
    }
  }, []);

  return (
    <div className={`${Logincss.modal}`}>
      <div className={`${Logincss.divSection2}`}>
        <section className={`${Logincss.section2}`}>
          {verified===true&&
          (
            <LatoFont>
            <div className={`${Logincss.buttonSendDiv1}`}>
              <FontAwesomeIcon icon={faCheck} className={`${Logincss.v}`} size="xl"/>
              <p className={`${Logincss.login}`}>Your account has been activated</p>
              <RedirectWithDelay to="/login" delay={2000} />
            </div>
            </LatoFont>
          )}
        </section>
      </div> 
    </div>
  );
          }

export default VerifiedEmail;
