import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import http from "../http-common";
import TrainingsCSS from "./Trainings.module.css";
import {RobotoFont, LatoFont} from '../fonts';
import { useNavigate } from "react-router-dom";
  
function Trainings() {
  const [trainingsList, setTrainingsList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    try{
      if (!navigator.onLine) {
        navigate("/offline");
      }
      else{
        http.get(`/trainings/opened`).then((res) =>{
          setTrainingsList(res.data);
        });
      }
    } catch (error) {
          console.error('Error fetching trainings:', error.response.data.message);
    }
  }, []);
  return (
    <div className={`${TrainingsCSS.general}`}>
      <div className={`${TrainingsCSS.base}`}>
        <RobotoFont>
        <p className={`${TrainingsCSS.overview}`}>Unlock the Secrets of Smart Machines: Join our AI and Cybersecurity Journey
        </p>
        <p className={`${TrainingsCSS.overviewMobile}`}>Current Courses</p>
        </RobotoFont>
        {trainingsList.map((training) => {
          return (
            <Link to={`/training/${training._id}`}>
            <div className={`${TrainingsCSS.position}`}>
              <LatoFont>
                <h4 className={`${TrainingsCSS.heading}`}>{training.title}</h4>
                <p className={`${TrainingsCSS.posDescription}`}>{training.place}<br></br></p>
              </LatoFont>
            </div> 
            </Link>  
          );
        })}
        
      </div>
    </div>
  );
}

export default Trainings;