import React from "react";
import ContactCSS from "./Contact.module.css";
import {RobotoFont, LatoFont} from '../../fonts';
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
      <div className={ContactCSS.bodyBackground}>
        <div className={ContactCSS.application}>
            <RobotoFont>
              <h1>
              <span className={ContactCSS.inapp1}>Seamless Connections</span><br></br><span className={ContactCSS.inapp2}>Boundless Opportunities</span>
              </h1>
            </RobotoFont>
        </div>
        <div className={ContactCSS.deviz}>
          <Link to="/contact" className={ContactCSS.navigationContact}>
            <LatoFont>
              <button  className={`${ContactCSS.contact}`}>
                GET <i>in</i> TOUCH
              </button>
            </LatoFont>
          </Link>
          
        </div> 
      </div>
    </>
  );
}

export default Contact;