// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/home/welcome.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Company_bodyBackground__\\+Cd6P{
    background-color: black;
    background-image: linear-gradient(to bottom, 
    rgba(25, 27, 35, 1),  
    rgba(25, 27, 35, 0)
    ),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
}
.Company_application__UQJi4{
    padding: 100px 10px 0px 10px;
    /*color:white;*/
    color:rgb(229, 227, 227);
    text-align: center;
    font-size: 70px;
    letter-spacing: 2px;
}
.Company_deviz__MgS-I{
    padding: 20px 10px 180px 10px;
    color: #f3530e;
    text-align: center;
    font-size: 25px;
    line-height:40px;
    letter-spacing: 1px;
}
@media only screen and (max-width: 900px) {
    .Company_application__UQJi4{
        padding: 50px 40px 0px 40px;
        color:white;
        text-align: center;
        font-size: 35px;
    }
    .Company_deviz__MgS-I{
        padding: 10px 40px 90px 40px;
        color: #f3530e;
        text-align: center;
        font-size: 15px;
        line-height:30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/home/Company.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB;;;;2CAIoC;IACpC,4BAA4B;IAC5B,sBAAsB;AAC1B;AACA;IACI,4BAA4B;IAC5B,eAAe;IACf,wBAAwB;IACxB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,6BAA6B;IAC7B,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI;QACI,2BAA2B;QAC3B,WAAW;QACX,kBAAkB;QAClB,eAAe;IACnB;IACA;QACI,4BAA4B;QAC5B,cAAc;QACd,kBAAkB;QAClB,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".bodyBackground{\n    background-color: black;\n    background-image: linear-gradient(to bottom, \n    rgba(25, 27, 35, 1),  \n    rgba(25, 27, 35, 0)\n    ),\n    url(\"../../images/home/welcome.png\");\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n.application{\n    padding: 100px 10px 0px 10px;\n    /*color:white;*/\n    color:rgb(229, 227, 227);\n    text-align: center;\n    font-size: 70px;\n    letter-spacing: 2px;\n}\n.deviz{\n    padding: 20px 10px 180px 10px;\n    color: #f3530e;\n    text-align: center;\n    font-size: 25px;\n    line-height:40px;\n    letter-spacing: 1px;\n}\n@media only screen and (max-width: 900px) {\n    .application{\n        padding: 50px 40px 0px 40px;\n        color:white;\n        text-align: center;\n        font-size: 35px;\n    }\n    .deviz{\n        padding: 10px 40px 90px 40px;\n        color: #f3530e;\n        text-align: center;\n        font-size: 15px;\n        line-height:30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyBackground": `Company_bodyBackground__+Cd6P`,
	"application": `Company_application__UQJi4`,
	"deviz": `Company_deviz__MgS-I`
};
export default ___CSS_LOADER_EXPORT___;
