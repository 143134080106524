import React from "react";
import { useEffect } from "react";
import Company from "../components/home/Company";
import Choose from "../components/home/Choose";
import Tech from "../components/home/Tech";
import Carousel from "../components/home/Carousel";
import Professional from "../components/home/Professional";

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <>
      <Company></Company>
      <Carousel></Carousel>
      <Professional></Professional>
      <Choose></Choose>
      <Tech></Tech>
    </>
  );
}

export default Home;
