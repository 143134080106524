// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Top_toTopButtonNotVisible__qO66V {
    position: fixed;
    bottom: 40px;
    right: 30px;
    display: none;
    border: none;
    cursor: pointer;
    color:#f3530e;
    transform:rotate(-90deg);
  }
  
  .Top_toTopButtonVisible__qeVXs {
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: block;
    border: 2px solid #f3530e;
    cursor: pointer;
    color:#f3530e;
    transform:rotate(-90deg);
    padding:5px 9.5px;
    border-radius: 50%;
  }
  .Top_toTopButtonVisible__qeVXs:hover{
    opacity: 0.5;
  }
  @media only screen and (max-width: 1000px) {
    .Top_toTopButtonVisible__qeVXs {
      bottom: 50px;
      right: 20px;
      border: 0px solid black;
      color:rgb(141, 139, 139);
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/services/Top.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,YAAY;IACZ,eAAe;IACf,aAAa;IACb,wBAAwB;EAC1B;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,WAAW;IACX,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,wBAAwB;IACxB,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,YAAY;EACd;EACA;IACE;MACE,YAAY;MACZ,WAAW;MACX,uBAAuB;MACvB,wBAAwB;IAC1B;EACF","sourcesContent":[".toTopButtonNotVisible {\n    position: fixed;\n    bottom: 40px;\n    right: 30px;\n    display: none;\n    border: none;\n    cursor: pointer;\n    color:#f3530e;\n    transform:rotate(-90deg);\n  }\n  \n  .toTopButtonVisible {\n    position: fixed;\n    bottom: 50px;\n    right: 50px;\n    display: block;\n    border: 2px solid #f3530e;\n    cursor: pointer;\n    color:#f3530e;\n    transform:rotate(-90deg);\n    padding:5px 9.5px;\n    border-radius: 50%;\n  }\n  .toTopButtonVisible:hover{\n    opacity: 0.5;\n  }\n  @media only screen and (max-width: 1000px) {\n    .toTopButtonVisible {\n      bottom: 50px;\n      right: 20px;\n      border: 0px solid black;\n      color:rgb(141, 139, 139);\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toTopButtonNotVisible": `Top_toTopButtonNotVisible__qO66V`,
	"toTopButtonVisible": `Top_toTopButtonVisible__qeVXs`
};
export default ___CSS_LOADER_EXPORT___;
