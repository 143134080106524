import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import http from "../http-common";
import Cookies from 'js-cookie';
import ProfileImg from "../components/profile/ProfileImg";
import RedirectWithDelay from '../components/Auth/redirect';


function ProfileImgUpload() {
  const user = Cookies.get('user');

  return (
    <>
      <div>
        {!user ?
        (
          <h2>No user logged in</h2>
        ):
        (
          <>
            {!navigator.onLine?
            (
              <RedirectWithDelay to="/offline" delay={0}/>
            ):
            (
              <ProfileImg></ProfileImg>
            )
            }
          </>
        )}
      </div>
    </>
  );
}

export default ProfileImgUpload;