import React from "react";

function Clients() {
  return (
    <>
      <h1>Clients page:</h1>
      List of clients:
    </>
  );
}

export default Clients;