// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_center__HG0A-{
    text-align: center;
}
.Carousel_centerImg__3uzGI{
    text-align: center;
    padding-bottom: 20px;
}
.Carousel_padding__Z9S\\+1{
    padding-top: 50px;
}
.Carousel_img__hzSTG{
    height: 50px;
}
.Carousel_text__-XD4M{
    margin:0px;
    padding:0px;
}
.Carousel_link__toLIz{
    color: black;
    text-decoration: none;
}
.Carousel_p__zlpZP{
    overflow: hidden;
    -webkit-hyphens:none;
            hyphens:none;
    font-size: 20px;
    height:107px;
    position:sticky;
    z-index: -1;
}
.Carousel_leftText__JPN9F{
    width:15%;
}
.Carousel_rightText__3HwZx{
    width:15%;
}
.Carousel_centerText__KuDcP{
    width:70%;
    background-image: linear-gradient(0deg, 
    rgba(255, 255, 255, 1),  
    rgba(255, 255, 255, 0)
    );
    height:110px;
    margin-bottom: 90px;
}
.Carousel_heading__QgQe2{
    font-size: 40px;
    line-height:70px;
}
.Carousel_ui__WOGyX{
    letter-spacing: 2px;
}

@media only screen and (max-width: 900px) {
    .Carousel_leftText__JPN9F{
        width:10%;
    }
    .Carousel_rightText__3HwZx{
        width:10%;
    }
    .Carousel_centerText__KuDcP{
        width:80%;
    }
    .Carousel_p__zlpZP{
        font-size: 15px;
    }
    .Carousel_heading__QgQe2{
        font-size: 25px;
        line-height:50px;
    }
    .Carousel_img__hzSTG{
        height: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/home/Carousel.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,YAAY;AAChB;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,YAAY;IACZ,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,oBAAY;YAAZ,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,eAAe;IACf,WAAW;AACf;AACA;IACI,SAAS;AACb;AACA;IACI,SAAS;AACb;AACA;IACI,SAAS;IACT;;;KAGC;IACD,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,SAAS;IACb;IACA;QACI,SAAS;IACb;IACA;QACI,SAAS;IACb;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".center{\n    text-align: center;\n}\n.centerImg{\n    text-align: center;\n    padding-bottom: 20px;\n}\n.padding{\n    padding-top: 50px;\n}\n.img{\n    height: 50px;\n}\n.text{\n    margin:0px;\n    padding:0px;\n}\n.link{\n    color: black;\n    text-decoration: none;\n}\n.p{\n    overflow: hidden;\n    hyphens:none;\n    font-size: 20px;\n    height:107px;\n    position:sticky;\n    z-index: -1;\n}\n.leftText{\n    width:15%;\n}\n.rightText{\n    width:15%;\n}\n.centerText{\n    width:70%;\n    background-image: linear-gradient(0deg, \n    rgba(255, 255, 255, 1),  \n    rgba(255, 255, 255, 0)\n    );\n    height:110px;\n    margin-bottom: 90px;\n}\n.heading{\n    font-size: 40px;\n    line-height:70px;\n}\n.ui{\n    letter-spacing: 2px;\n}\n\n@media only screen and (max-width: 900px) {\n    .leftText{\n        width:10%;\n    }\n    .rightText{\n        width:10%;\n    }\n    .centerText{\n        width:80%;\n    }\n    .p{\n        font-size: 15px;\n    }\n    .heading{\n        font-size: 25px;\n        line-height:50px;\n    }\n    .img{\n        height: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": `Carousel_center__HG0A-`,
	"centerImg": `Carousel_centerImg__3uzGI`,
	"padding": `Carousel_padding__Z9S+1`,
	"img": `Carousel_img__hzSTG`,
	"text": `Carousel_text__-XD4M`,
	"link": `Carousel_link__toLIz`,
	"p": `Carousel_p__zlpZP`,
	"leftText": `Carousel_leftText__JPN9F`,
	"rightText": `Carousel_rightText__3HwZx`,
	"centerText": `Carousel_centerText__KuDcP`,
	"heading": `Carousel_heading__QgQe2`,
	"ui": `Carousel_ui__WOGyX`
};
export default ___CSS_LOADER_EXPORT___;
