// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Offline_general__VaRPS{
  padding: 150px 20px 150px 20px;
  text-align: center;
  color: #ffffff;
  width:100%;
  background-color: #191b23;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Offline.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,cAAc;EACd,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":[".general{\n  padding: 150px 20px 150px 20px;\n  text-align: center;\n  color: #ffffff;\n  width:100%;\n  background-color: #191b23;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"general": `Offline_general__VaRPS`
};
export default ___CSS_LOADER_EXPORT___;
