import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import http from "../../http-common";
import ProfileImgCSS from "./ProfileImg.module.css";
import ProfileCVCSS from "./ProfileCV.module.css";
import { useUser } from '../Auth/userContext';
import {LatoFont} from '../../fonts';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Cookies from 'js-cookie';

function ProfileCV() {
  const { user } = useUser();
  const { updateUser } = useUser();
  const [cv, setCv] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const navigationBack = Cookies.get('redirect');
  const maxSize = 300 * 1024; // 300kb in bytes

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      if(checkFile(file)&& file.size < maxSize){
        setError("");
        setUploadedFile(true);
        setCv(file);
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
          'pdfjs-dist/build/pdf.worker.min.js',
          import.meta.url,
        ).toString();
      }
      else{
        setError("pdf format only and < 300kb");
      }
      
    }
    else{
        setError("No file found");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if(checkFile(file)&& file.size < maxSize){
        setError("");
        setUploadedFile(true);
        setCv(file);
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
          'pdfjs-dist/build/pdf.worker.min.js',
          import.meta.url,
        ).toString();
      }
      else{
        setError("pdf format only and < 300kb");
      }
      
    }
    else{
        setError("No file found");
    }
  };

  const checkFile = (file) => {
    if (file) {
      if (file.type === "application/pdf"){
        return true;
      }
      else return false;
    } 
    else return false;
  };

  const reset = () => {
    setCv(null);
    setUploadedFile(false);
    window.location.reload();
  }

  const sendFileToServer = async () => {
    const formData = new FormData();
    formData.append('file', cv);

    try {
        const response = await http.post("users/uploadfile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const updatedUser = await http.put(`users/${user.id}`, {cv: response.data.key});
        updateUser(
        {
            id: updatedUser._id,
            email: updatedUser.email,
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
            photo: updatedUser.photo,
            cv: updatedUser.cv
        });
        setUploadedFile(false);
        setError("");
        if(navigationBack){
          navigate(JSON.parse(navigationBack).redirection);
        }
        else{
          navigate("/profile");
        }
        window.location.reload();
    } catch (error) {
      console.log('Error uploading image');
      window.location.reload();
    }
  };

  return (
    <>
        <div className={`${ProfileImgCSS.basicDiv}`}>
            <div className={`${ProfileImgCSS.firstDiv}`}></div>
            <div className={`${ProfileImgCSS.secondDiv}`}>
              <div className={`${ProfileImgCSS.customfileupload}`}>
                <LatoFont>
                <p className={`${ProfileImgCSS.error}`}>{error}<span style={{color: "#191b23"}}>.</span></p>
                {uploadedFile === false?
                (
                    <button className={`${ProfileImgCSS.uploadButton}`}>
                        <label htmlFor="fileInput">NEW CV (PDF)</label>
                    </button>
                ):
                (
                  <>
                    <button 
                        onClick={reset} 
                        className={`${ProfileImgCSS.uploadButton}`} style={{marginRight:"10px"}}
                    >
                            RESET
                    </button>
                    <button 
                        onClick={sendFileToServer} 
                        className={`${ProfileImgCSS.uploadButton}`}
                    >
                            UPLOAD
                    </button>
                  </>
                )}
                
                </LatoFont>
                <input type="file" id="fileInput" accept=".pdf" onChange={handleFileChange}/>
              </div>
            </div>
                {!cv && 
                  <div className={`${ProfileCVCSS.imgDiv}`} onDragOver={handleDragOver}
                    onDrop={handleDrop}>
                  </div>
                }
                {cv && 
                  <div className={`${ProfileCVCSS.imgDiv}`}>
                    <Document className={`${ProfileCVCSS.pdfDiv}`} file={cv}>
                      <Page 
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        customTextRenderer={false} 
                        scale = {1}
                        pageNumber={1} />
                    </Document>
                  </div>
                }
        </div>
    </>
  );
}

export default ProfileCV;