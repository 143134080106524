import React, { useState, useEffect } from "react";
import Logincss from "./login.module.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import http from "../../http-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { LatoFont, RobotoFont } from "../../fonts";
import RedirectWithDelay from './redirect';
import { useUser } from './userContext';


function LoginForm() {
  const [formData, setFormData] = useState({
    email: '',
    password:''
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^.+$/;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showScrollingDiv, setShowScrollingDiv] = useState(false);
  const [user, setUser] = useState(Cookies.get('user'));
  const navigate = useNavigate();
  const { updateUser } = useUser();
  const navigationBack = Cookies.get('redirect');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    handleScroll();
  }, []);

  const handleScroll = () => {
    if(!user){
      const contentElement = document.getElementById('content');
      const contentRect = contentElement.getBoundingClientRect();

      if (contentRect.left <= 0) {
        setShowScrollingDiv(true);
      } else {
        setShowScrollingDiv(false);
      }
    }
  };

  const redirectPage = () => {
    if(navigationBack){
      navigate(JSON.parse(navigationBack).redirection);
    }
    else{
      navigate("/");
    }
  };

  const handleChangeEmail = (e) => {
    setFormData({
      ...formData,
      email: e.target.value
    });
    setIsEmailValid(emailRegex.test(e.target.value));
    setError("");
  };

  const handleChangePassword = (e) => {
    setFormData({
      ...formData,
      password: e.target.value
    });
    setIsPasswordValid(passwordRegex.test(e.target.value));
    setError("");
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!navigator.onLine) {
        navigate("/offline");
      }
      else{
        await http.post('users/login', formData).then((response) =>{
            if(response.data.error){
              setError(response.data.error);
            }
            else{
              setSuccess(response.data.message);
              setError('');
              Cookies.set("user", JSON.stringify(response.data));
              setFormData({
                email: '',
                password: ''
              });
              setIsEmailValid(false);
              setIsPasswordValid(false);
              window.location.reload();
            } 
        });
      }
    } catch (error) {
      console.log("nothing happened")
      setSuccess("");
      setError(error);
    }
  };
  
  return (
    <div className={`${Logincss.modal}`}>
      {(!user) &&
        <div className={showScrollingDiv ? `${Logincss.divSection1}`: `${Logincss.divSection}`} id="content">
          <section className={`${Logincss.section1}`}>
            <RobotoFont>
              <p className={`${Logincss.heading}`}>
              <span className={`${Logincss.headingIdea}`}>Welcome back</span><br></br> 
              <span className={`${Logincss.headingSignin}`}>Sign in to continue</span>
              </p>
            </RobotoFont>
          </section>
        </div>
      }
      <div className={`${Logincss.divSection2}`}>
        <section className={`${Logincss.section2}`}>
          {(!user)?
          (
            <form onSubmit={handleSubmit} name="login">
              <LatoFont>
              <input
                  className={isEmailValid===false? `${Logincss.inputError}`: `${Logincss.input}`}
                  name="email"
                  placeholder="EMAIL*"
                  maxLength="100"
                  value={formData.email}
                  onChange={handleChangeEmail}
              /><br></br>
              <input
                  className={isPasswordValid===false? `${Logincss.inputError}`: `${Logincss.input}`}
                  type="password"
                  name="password"
                  value={formData.password}
                  maxLength="100"
                  onChange={handleChangePassword}
                  placeholder="PASSWORD"
              />
              </LatoFont>
              <RobotoFont>
              <div className={`${Logincss.error}`}>
              {(isEmailValid===false || isPasswordValid===false)?
              (
                <p>* Please complete all required fields correctly</p>
              ):
              (
                <p>{error}<span style={{color: "#191b23"}}>.</span></p>
              )}
                  
              </div>
              </RobotoFont>
              <LatoFont>
              <div className={`${Logincss.buttonSendDiv}`}>
                {(isEmailValid===false || isPasswordValid===false)? 
                (
                  <>
                  <button disabled className={`${Logincss.buttonDisabled}`} type="submit">LOGIN</button>
                  <p className={`${Logincss.signUp}`}>
                    <a href="/signup">Sign Up</a> <a href="/reset" style={{marginLeft: "30px"}}>Reset password</a>
                  </p>
                  </>
                ):
                (
                  <>
                  <button className={`${Logincss.button}`}  type="submit">LOGIN</button>
                  <p className={`${Logincss.signUp}`}>
                    <a href="/signup">Sign Up</a> <a href="/reset" style={{marginLeft: "30px"}}>Reset password</a>
                  </p>
                  </>
                )}
              </div>
              </LatoFont>
            </form>
          ):(
            <LatoFont>
            <div className={`${Logincss.buttonSendDiv1}`} onClick={redirectPage}>
              <FontAwesomeIcon icon={faCheck} className={`${Logincss.v}`} size="xl"/>
              <p className={`${Logincss.login}`}>Login successfull</p>
              {navigationBack?
              (
                <RedirectWithDelay to={`${JSON.parse(navigationBack).redirection}`} delay={2000} />
              ):
              (
                <RedirectWithDelay to="/" delay={2000} />
              )}
              
            </div>
            </LatoFont>
          )}
        </section>
      </div> 
    </div>
  );
}

export default LoginForm;

