import React from "react";
import { useState, useEffect } from "react";
import ServicesCSS from "./Services.module.css";
import { LatoFont, RobotoFont } from "../fonts";
import Web from "../images/home/web_dev_orange.png";
import Training from "../images/home/training_orange.png";
import Custom from "../images/home/softwear_en_orange.png";
import Brand from "../images/home/brand.png";
import Design from "../images/home/design.png";
import WebDev from "../components/services/Web";
import Ui from "../components/services/Ui";
import CustomApp from "../components/services/Custom";
import Branding from "../components/services/Branding";
import ToTop from "../components/services/Top";
import TrainingCorp from "../components/services/TrainingService";


function Services() {

  const [service, setService] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  function scrollToTop(id) {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 180,
        behavior: 'smooth',
      });
    }
  }

  function showService(serv){
    setService(serv);
  }

  function noService(){
    setService("");
  }

  return (
    <>
    <div className={`${ServicesCSS.modal}`}>
      <div className={`${ServicesCSS.divSection2}`}>
        <div className={`${ServicesCSS.circleAndText}`}>
          <div className={`${ServicesCSS.circleOnTop}`}>
              <LatoFont>
                <p className={`${ServicesCSS.serviceName}`}>{service}</p>
              </LatoFont>
          </div>
            <ul className={`${ServicesCSS.circle}`}>
              <li className={`${ServicesCSS.li}`}>
                <div className={`${ServicesCSS.text}`}>
                  <div onClick={() => scrollToTop('web')}>
                    <img src={Web} onMouseEnter={()=>showService("Web Development")} onMouseLeave={noService} alt="web" className={`${ServicesCSS.img}`}/>
                  </div>
                </div>
              </li>
              <li className={`${ServicesCSS.li}`}>
                <div className={`${ServicesCSS.text}`}>
                  <div onClick={() => scrollToTop('ui')}>
                    <img src={Design} onMouseEnter={()=>showService("UX/UI Design")} onMouseLeave={noService} alt="design" className={`${ServicesCSS.img}`}/>
                  </div>
                </div>
              </li>
              <li className={`${ServicesCSS.li}`}>
                <div className={`${ServicesCSS.text}`}>
                  <div onClick={() => scrollToTop('custom')}>
                    <img src={Custom} onMouseEnter={()=>showService("Custom App Development")} onMouseLeave={noService} alt="custom" className={`${ServicesCSS.img}`}/>
                  </div>
                </div>
              </li>
              <li className={`${ServicesCSS.li}`}>
                <div className={`${ServicesCSS.text}`}>
                  <div onClick={() => scrollToTop('branding')}>
                    <img src={Brand} onMouseEnter={()=>showService("Branding")} onMouseLeave={noService} alt="brand" className={`${ServicesCSS.img}`}/>
                  </div>
                </div>
              </li>
              <li className={`${ServicesCSS.li}`}>
                <div className={`${ServicesCSS.text}`}>
                  <div onClick={() => scrollToTop('training')}>
                    <img src={Training} onMouseEnter={()=>showService("Corporate Training")} onMouseLeave={noService} alt="training" className={`${ServicesCSS.img}`}/>
                  </div>
                </div>
              </li>
            </ul>
        </div>  
      </div> 
      <div className={`${ServicesCSS.divSection1}`}>
        <section className={`${ServicesCSS.section1}`}>
          <RobotoFont>
            <p className={`${ServicesCSS.heading}`}><span className={`${ServicesCSS.classWord}`}>Welcome</span> to Our Web<br></br>
            Development <span className={`${ServicesCSS.classWord}`}>Services</span>
            </p>
          </RobotoFont>
          <LatoFont>
            <p className={`${ServicesCSS.text1}`}>
            Unlock the full potential of your online presence with our cutting-edge web 
            development solutions. At VELEAX, we blend creativity, innovation, and technical 
            expertise to craft websites that not only captivate audiences but also drive results. 
            </p>
          </LatoFont>
        </section>
      </div>
    </div>
    <ToTop></ToTop>
    <WebDev></WebDev>
    <Ui></Ui>
    <CustomApp></CustomApp>
    <Branding></Branding>
    <TrainingCorp></TrainingCorp>
    </>
  );
}

export default Services;
