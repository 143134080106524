import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import http from "../../http-common";
import Cookies from 'js-cookie';
import ProfileDetailsCSS from "./ProfileDetails.module.css";
import { useUser } from '../Auth/userContext';
import {LatoFont} from '../../fonts';
import 'react-phone-number-input/style.css';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { getCountryData, getCountryCode } from 'countries-list';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFilePdf, faPenToSquare, faFile} from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
  


function ProfileDetails() {
  const { user } = useUser();
  const [userLogged, setUserLogged] = useState({});
  const tokenId = Cookies.get('user');
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [phone, setPhone] = useState(userLogged.phone);
  const [linkedIn, setLinkedIn] = useState(userLogged.linkedIn);
  const fullName = (user.firstName+ " " +user.lastName).toUpperCase();
  const [countryCode, setCountryCode] = useState(getCountryCode(userLogged.country));
  const [applicationList, setApplicationList] = useState([]);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const urlRegex = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;
  const [isLinkedInValid, setIsLinkedInValid] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if(tokenId)
    {
      const fetchUser = async () => {
        try {
          const token = JSON.parse(tokenId).token;
  
          if (!token) {
            return;
          }
  
          const response = await http.get('users/user', {
            headers: {
              Authorization: token,
            },
          });
          const id = response.data.id;
          await http.get(`/users/id/${id}`).then((res) =>{
              setUserLogged(res.data);
              setPhone(res.data.phone);
              setLinkedIn(res.data.linkedIn);
          });
          await http.get(`/applications/applied/${id}`).then((res) =>{
            setApplicationList(res.data);
          });
        } catch (error) {
          console.error('Error fetching user details:', error.response.data.message);
        }
      };
      if (!navigator.onLine) {
        navigate("/offline");
      }
      else{
        fetchUser();
      }
    }
  }, []);

  const handleChangeLinkedIn = (e) => {
    setLinkedIn(e.target.value);
    setIsLinkedInValid(urlRegex.test(e.target.value));
  };

  const handleDelay = () => {
    // Set a delay of 2000 milliseconds (2 second)
    setTimeout(() => {window.location.reload()}, 2000);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(phone === undefined){
        const updatedUser = await http.put(`users/${userLogged._id}`, {phone:"", linkedIn:linkedIn, country:""});
        setUserLogged(updatedUser);  
      }
      else{
        const updatedUser = await http.put(`users/${userLogged._id}`, {phone:phone, linkedIn:linkedIn, country:getCountryData(countryCode).name});
        setUserLogged(updatedUser);
      }
      setSuccess("changes updated");
      handleDelay();
      
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
        <div className={`${ProfileDetailsCSS.basicDiv}`}>
            <div className={`${ProfileDetailsCSS.leftDiv}`}>
              <div className={`${ProfileDetailsCSS.leftTopDiv}`}>
                  <Link to="/profile&change&photo"><img className={`${ProfileDetailsCSS.imgDiv}`} 
                     src={`${process.env.REACT_APP_AWS_URL}/${user.photo}`} alt="user img"/>
                  </Link>
                  <br></br><Link to="/profile&change&photo"><FontAwesomeIcon icon={faPenToSquare} className={`${ProfileDetailsCSS.pen}`}/></Link>
                <LatoFont>
                  <p className={`${ProfileDetailsCSS.myProfile}`}>{fullName}<br></br>
                  <span className={`${ProfileDetailsCSS.email}`}>{user.email}</span></p>
                  <p className={`${ProfileDetailsCSS.cv}`}>
                    CURRICULUM VITAE 
                    {(userLogged.cv==="" || userLogged.cv===undefined)?
                    (
                      <>
                        <FontAwesomeIcon icon={faFile} className={`${ProfileDetailsCSS.file}`}/> 
                        <Link to="/profile&change&cv">
                          <FontAwesomeIcon icon={faPenToSquare} className={`${ProfileDetailsCSS.pen}`}/>
                        </Link>
                      </>
                    ):
                    (
                      <>
                        <a href={`${process.env.REACT_APP_AWS_URL}/${userLogged.cv}`} target="_blank">
                          <FontAwesomeIcon icon={faFilePdf} className={`${ProfileDetailsCSS.filePDF}`}/>
                        </a> 
                        <Link to="/profile&change&cv">
                          <FontAwesomeIcon icon={faPenToSquare} className={`${ProfileDetailsCSS.pen}`}/>
                        </Link>
                      </>
                    )}
                  </p>
                  <p className={`${ProfileDetailsCSS.buttonChangePass}`}>
                    <span className={`${ProfileDetailsCSS.buttonChangePass1}`} >NEW PASSWORD </span>
                    <Link to="/changePassword">
                      <FontAwesomeIcon icon={faPenToSquare} className={`${ProfileDetailsCSS.pen}`}/>
                    </Link>
                  </p>
                  
                </LatoFont>
              </div>
              <LatoFont>
              <div className={`${ProfileDetailsCSS.leftBottomDiv}`}>
                <form onSubmit={handleSubmit} name="profile">
                  <PhoneInput
                        name= "phone"
                        defaultCountry="CA"
                        className={
                          (isPossiblePhoneNumber(String(phone))===false && phone!==undefined && phone!=="")?
                          `${ProfileDetailsCSS.inputHalf1Error}`
                          :
                          `${ProfileDetailsCSS.inputHalf1}`
                        }
                        placeholder="PHONE"
                        value={phone}
                        onChange={setPhone} 
                        onCountryChange={setCountryCode}
                  />
                  <input
                        name="linkedIn"
                        className={isLinkedInValid===true?
                          `${ProfileDetailsCSS.inputHalf}`
                          :
                          `${ProfileDetailsCSS.inputHalfError}`
                        }
                        placeholder="LinkedIn URL"
                        maxLength="100"
                        value={linkedIn}
                        onChange={handleChangeLinkedIn}
                  />
                  <div className={`${ProfileDetailsCSS.leftBottomBottomDiv}`}>
                    <p className={`${ProfileDetailsCSS.error}`}>
                      {error} {(isPossiblePhoneNumber(String(phone))===false && phone!==undefined && phone!=="") && "Incorrect phone number"}
                      <span style={{color:"#840ef3"}}>{success}</span>
                      <span style={{color:"white"}}>.</span>
                    </p>
                    {((isPossiblePhoneNumber(String(phone)) || phone === "" || phone === undefined)&&isLinkedInValid===true)? 
                    (
                      <button className={`${ProfileDetailsCSS.button}`} type="submit">UPDATE</button>
                    ): 
                    (
                      <button disabled className={`${ProfileDetailsCSS.buttonDisabled}`}>UPDATE</button>
                    )}
                  </div>
                  
                </form>
              </div>
              </LatoFont>
            </div>
            <div className={`${ProfileDetailsCSS.rightDiv}`}>
              <div className={`${ProfileDetailsCSS.top}`}>
                  <LatoFont>
                    <h6 className={`${ProfileDetailsCSS.appStatusHeading}`}>MY APPLICATIONS</h6>
                  </LatoFont>
                  {applicationList.length>0?
                  (
                  <div className={`${ProfileDetailsCSS.topDiv}`}>
                    {applicationList.map((application) => {
                      return (
                      <>
                        <LatoFont>
                          <Link to={`/position/${application.appliedFor._id}`}>
                            <h6 className={`${ProfileDetailsCSS.posisionHeading}`}>{application.appliedFor.title}</h6>
                          </Link>
                          <p className={`${ProfileDetailsCSS.appDate}`}>{new Date(application.createdAt).toLocaleDateString(undefined, options)}</p>
                          <p className={`${ProfileDetailsCSS.appStatus}`}>Application Status <span className={`${ProfileDetailsCSS.appStatusSpan}`}>{application.status}</span></p>
                        </LatoFont>  
                        <hr></hr>
                        </>
                      );
                    })}
                  </div>
                  ):
                  (
                    <div className={`${ProfileDetailsCSS.topDiv1}`}>
                      <p>Currently no applications</p>
                    </div>
                  )}
              </div>
              <div className={`${ProfileDetailsCSS.bottomDiv}`}>
                  <LatoFont>
                    <h6 className={`${ProfileDetailsCSS.appStatusHeading}`}>MY COURSES</h6>
                  </LatoFont>
                  <div className={`${ProfileDetailsCSS.topDiv1}`}>
                      <p>Currently no courses</p>
                  </div>
              </div>
            </div>
        </div>
    </>
  );
}

export default ProfileDetails;