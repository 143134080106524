import React from "react";
import CompanyCSS from "./Company.module.css";
import {RobotoFont, LatoFont} from '../../fonts';

function Company() {
  return (
    <>
      <div className={CompanyCSS.bodyBackground}>
        <div className={CompanyCSS.application}>
            <RobotoFont>
              <p>
                Full Stack App<br></br> Development Services
              </p>
            </RobotoFont>
        </div>
        <div className={CompanyCSS.deviz}>
          <LatoFont>
            <p> 
                Enabling businesses to reach new 
                heights through <br></br>software prowess
            </p>
          </LatoFont>
        </div> 
      </div>
    </>
  );
}

export default Company;