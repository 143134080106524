import React from "react";
import { useState } from "react";
import WebCSS from "./Web.module.css";
import {RobotoFont, LatoFont} from '../../fonts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

function TrainingService() {
  const[one, setOne]=useState(false);
  const[two, setTwo]=useState(false);
  const[three, setThree]=useState(false);
  const[four, setFour]=useState(false);
  const[five, setFive]=useState(false);
  const[six, setSix]=useState(false);
  const[seven, setSeven]=useState(false);

  function showText(figure){
    switch(figure) {
      case 1:
        if(one===false){
          setOne(true);
        }
        else{
          setOne(false)
        };
        break;
      case 2:
        if(two===false){
          setTwo(true);
        }
        else{
          setTwo(false)
        };
        break;
      case 3:
        if(three===false){
          setThree(true);
        }
        else{
          setThree(false)
        };
        break;
      case 4:
        if(four===false){
          setFour(true);
        }
        else{
          setFour(false)
        };
        break;
      case 5:
        if(five===false){
          setFive(true);
        }
        else{
          setFive(false)
        };
        break;
      case 6:
        if(six===false){
          setSix(true);
        }
        else{
          setSix(false)
        };
        break;
      case 7:
        if(seven===false){
          setSeven(true);
        }
        else{
          setSeven(false)
        };
        break;
      default:
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(false);
        setSix(false);
        setSeven(false);
    }
  }
  return (
    <>
      <div className={`${WebCSS.container}`} id="training">
        <div className={`${WebCSS.overview}`}>
          <LatoFont>
            <p className={`${WebCSS.text}`}>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(1)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${one? WebCSS.signOpen : WebCSS.sign}`}/> Needs Assessment
              </a><br></br>
              <p className={`${one? WebCSS.subText : WebCSS.subTextNo}`}>
                  Conducting an analysis to identify specific areas where employee training is needed. 
                  Determining the skills, knowledge, or behaviors that require improvement.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(2)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${two? WebCSS.signOpen : WebCSS.sign}`}/> Training Content Development
              </a><br></br>
              <p className={`${two? WebCSS.subText : WebCSS.subTextNo}`}>
                  Creating training materials, such as presentations, handouts, e-learning modules, and interactive exercises. 
                  Ensuring that the content is engaging, informative, and aligned with adult learning principles.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(3)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${three? WebCSS.signOpen : WebCSS.sign}`}/> Delivery Methods
              </a><br></br>
              <p className={`${three? WebCSS.subText : WebCSS.subTextNo}`}>
                  Offering a variety of delivery methods, including in-person workshops, virtual 
                  training sessions, e-learning modules, and blended learning approaches.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(4)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${four? WebCSS.signOpen : WebCSS.sign}`}/> Technical and Soft Skills Training
              </a><br></br>
              <p className={`${four? WebCSS.subText : WebCSS.subTextNo}`}>
                  Offering training in technical skills related to the job, as well as soft skills such as 
                  communication, time management, and problem-solving.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(5)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${five? WebCSS.signOpen : WebCSS.sign}`}/> Professional Development Programs
              </a><br></br>
              <p className={`${five? WebCSS.subText : WebCSS.subTextNo}`}>
                  Providing opportunities for employees to enhance their career skills, including workshops 
                  on resume writing, networking, and career advancement.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(6)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${six? WebCSS.signOpen : WebCSS.sign}`}/> Assessment and Evaluation
              </a><br></br>
              <p className={`${six? WebCSS.subText : WebCSS.subTextNo}`}>
                  Conducting assessments and evaluations to measure the effectiveness of the training programs. 
                  Gathering feedback from participants to identify areas for improvement.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(7)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${seven? WebCSS.signOpen : WebCSS.sign}`}/> Continuous Learning Initiatives
              </a><br></br>
              <p className={`${seven? WebCSS.subText : WebCSS.subTextNo}`}>
                  Encouraging ongoing learning and development through resources, mentoring, and additional training opportunities.
              </p>
            </p>
          </LatoFont>
        </div>
        <div className={`${WebCSS.titleContainer}`}>
          <RobotoFont>
            <p className={`${WebCSS.title}`}><a href="/training">Corporate Training</a></p>
          </RobotoFont>
        </div>
      </div>
    </>
  );
}

export default TrainingService;