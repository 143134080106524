import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import http from "../http-common";
import PositionsCSS from "./Positions.module.css";
import {RobotoFont, LatoFont} from '../fonts';
import { useNavigate } from "react-router-dom";
  
function Positions() {
  const [positionsList, setPositionsList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    try{
      if (!navigator.onLine) {
        navigate("/offline");
      }
      else{
        http.get(`/positions/opened`).then((res) =>{
          setPositionsList(res.data);
        });
      }
    } catch (error) {
          console.error('Error fetching positions:', error.response.data.message);
    }
  }, []);
  return (
    <div className={`${PositionsCSS.general}`}>
      <div className={`${PositionsCSS.base}`}>
        <RobotoFont>
        <p className={`${PositionsCSS.overview}`}>Unleash your potential and join a team that values innovation and creativity.
          Check out our current openings below.
        </p>
        <p className={`${PositionsCSS.overviewMobile}`}>Current Positions</p>
        </RobotoFont>
        {positionsList.map((pos) => {
          return (
            <Link to={`/position/${pos._id}`}>
            <div className={`${PositionsCSS.position}`}>
              <LatoFont>
                <h4 className={`${PositionsCSS.heading}`}>{pos.title}</h4>
                <p className={`${PositionsCSS.posDescription}`}>{pos.place} ∙ {pos.shift} ∙ {pos.type} Level<br></br>
                    {pos.salary}<br></br> 
                </p>
              </LatoFont>
            </div> 
            </Link>  
          );
        })}
        
      </div>
    </div>
  );
}

export default Positions;