import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import http from "../../http-common";
import Cookies from 'js-cookie';
import ProfileImgCSS from "./ProfileImg.module.css";
import { useUser } from '../Auth/userContext';
import {LatoFont} from '../../fonts';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

function ProfileImg() {
  const { user } = useUser();
  const { updateUser } = useUser();
  Cookies.get('user');
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState();
  const [uploadedFile, setUploadedFile] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const maxSize = 200 * 1024; // 200kb in bytes

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      if(checkFile(file)&& file.size < maxSize){
        setError("");
        setUploadedFile(true);
        setImage(file);
        const reader = new FileReader();
        reader.onload = () => {
            // Display the selected image in the cropper
            setCropper(cropper => {
              if (cropper) {
                cropper.replace(reader.result);
              }
              return cropper;
            });
          };
          reader.readAsDataURL(file);
      }
      else{
        setError("jpeg, gif, png formats only and < 200kb");
      }
      
    }
    else{
        setError("No file found");
    }
  };

  const checkFile = (file) => {
    if (file) {
      if (
        file.type === "image/png" || 
        file.type === "image/jpg" || 
        file.type === "image/jpeg" || 
        file.type === "image/JPEG" ||
        file.type === "image/JPG" ||
        file.type === "image/gif"){
        return true;
      }
      else return false;
    } 
    else return false;
  };

  const handleCrop = () => {
    const croppedBlob = cropper.getCroppedCanvas().toBlob((blob) => {
      const croppedFile = new File([blob], `cropped-image.jpeg`, { type: `image/jpeg` });
      // Now 'croppedFile' is a file object that is sent to the server
      sendFileToServer(croppedFile);
    }, `image/jpeg`);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (file) {
      if(checkFile(file)&& file.size < maxSize){
        setError("");
        setUploadedFile(true);
        setImage(file);
        const reader = new FileReader();
        reader.onload = () => {
            // Display the selected image in the cropper
            setCropper(cropper => {
              if (cropper) {
                cropper.replace(reader.result);
              }
              return cropper;
            });
          };
          reader.readAsDataURL(file);
      }
      else{
        setError("jpeg, gif, png formats only and < 200kb");
      }
      
    }
    else{
        setError("No file found");
    }
  };

  const reset = () => {
    setImage(null);
    setUploadedFile(false);
    window.location.reload();
  }

  const sendFileToServer = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await http.post("users/uploadfile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const updatedUser = await http.put(`users/${user.id}`, {photo: response.data.key});
        updateUser(
        {
            id: updatedUser._id,
            email: updatedUser.email,
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
            photo: updatedUser.photo,
            cv: updatedUser.cv
        });
        setUploadedFile(false);
        setError("");
        navigate("/profile");
        window.location.reload();
    } catch (error) {
      console.log('Error uploading image');
      window.location.reload();
    }
  };

  return (
    <>
        <div className={`${ProfileImgCSS.basicDiv}`}>
            <div className={`${ProfileImgCSS.firstDiv}`}></div>
            <div className={`${ProfileImgCSS.secondDiv}`}>
            <div className={`${ProfileImgCSS.customfileupload}`}>
                <LatoFont>
                <p className={`${ProfileImgCSS.error}`}>{error}<span style={{color: "#191b23"}}>.</span></p>
                {uploadedFile === false?
                (
                    <button className={`${ProfileImgCSS.uploadButton}`}>
                        <label htmlFor="fileInput">NEW PHOTO</label>
                    </button>
                ):
                (
                  <>
                    <button 
                        onClick={reset} 
                        className={`${ProfileImgCSS.uploadButton}`} style={{marginRight:"10px"}}
                    >
                            RESET
                    </button>
                    <button 
                        onClick={handleCrop} 
                        className={`${ProfileImgCSS.uploadButton}`}
                    >
                            UPLOAD
                    </button>
                  </>
                )}
                
                </LatoFont>
                <input type="file" id="fileInput" accept=".png, .jpg, .jpeg, .JPEG, .JPG, .gif" onChange={handleFileChange}/>
            </div>
            </div>
            {uploadedFile === false?
            ( 
                <img className={`${ProfileImgCSS.imgDiv}`} 
                  src={`${process.env.REACT_APP_AWS_URL}/${user.photo}`} alt="user img" onDragOver={handleDragOver}
                  onDrop={handleDrop}/>
            ):
            (
                <Cropper
                    //style={{ height: 400, width: '100%' }}
                    className={`${ProfileImgCSS.imgDiv}`}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    guides={true}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                    setCropper(instance);
                    }}
                />
            )}  
        </div>
    </>
  );
}

export default ProfileImg;