import styled from "styled-components";

export const RobotoFont = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');
  & p {
    font-family: 'Roboto Slab', serif;
  }
  & h1 {
    font-family: 'Roboto Slab', serif;
  }
  & h2 {
    font-family: 'Roboto Slab', serif;
  }
  & h3 {
    font-family: 'Roboto Slab', serif;
  }
  & h4 {
    font-family: 'Roboto Slab', serif;
  }
  & h5 {
    font-family: 'Roboto Slab', serif;
  }
  & h6 {
    font-family: 'Roboto Slab', serif;
  }
`;

export const LatoFont = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@100&display=swap');
  & p {
    font-family: 'Lato', sans-serif;
  }
  & nav {
    font-family: 'Lato', sans-serif;
  }
  & span {
    font-family: 'Lato', sans-serif;
  }
  & h1 {
    font-family: 'Lato', sans-serif;
  }
  & h2 {
    font-family: 'Lato', sans-serif;
  }
  & h3 {
    font-family: 'Lato', sans-serif;
  }
  & h4 {
    font-family: 'Lato', sans-serif;
  }
  & h5 {
    font-family: 'Lato', sans-serif;
  }
  & h6 {
    font-family: 'Lato', sans-serif;
  }
`;