import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import SignUp from "./components/Auth/signup";
import VerifiedEmail from "./components/Auth/verifiedEmail";
import LoginForm from "./components/Auth/loginform";
import Reset from "./components/Auth/reset";
import ResetPassword from "./components/Auth/resetPassword";
import ChangePassword from "./components/Auth/changePassword";
import Profile from "./pages/Profile";
import ProfileImgUpload from "./pages/ProfileImgUpload";
import ProfileCVUpload from "./pages/ProfileCVUpload";
import Positions from "./pages/Positions";
import Position from "./pages/Position";
import Research from "./pages/Research";
import Application from "./pages/Application";
import Trainings from "./pages/Trainings";
import Training from "./pages/Training";
import Clients from "./pages/Clients";
import Offline from "./pages/Offline";
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Admin from "./pages/Admin";
import Hr from "./pages/Hr";
import { useUser } from './components/Auth/userContext';

const PrivateRoute = ({ element }) => {
  const { user } = useUser();

  return user ? element : <Navigate to="/login" replace />;
};

function App() {
  return (
    <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="verified/:id" element={<VerifiedEmail />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="reset" element={<Reset />} />
          <Route path="resetPassword/:id" element={<ResetPassword />} />
          <Route path="changePassword" element={<ChangePassword />} />
          <Route path="profile" element={<PrivateRoute element={<Profile />}/>} />
          <Route path="profile&change&photo" element={<PrivateRoute element={<ProfileImgUpload />}/>} />
          <Route path="profile&change&cv" element={<PrivateRoute element={<ProfileCVUpload />}/>} />
          <Route path="positions" element={<Positions/>} />
          <Route path="position/:id" element={<Position/>} />
          <Route path="application/:id" element={<Application/>} />
          <Route path="trainings" element={<Trainings/>} />
          <Route path="training/:id" element={<Training/>} />
          <Route path="clients" element={<Clients/>} />
          <Route path="offline" element={<Offline/>} />
          <Route path="services" element={<Services/>} />
          <Route path="research" element={<Research/>} />
          <Route path="about" element={<About/>} />
          <Route path="contact" element={<Contact/>} /> 
          <Route path="admin" element={<PrivateRoute element={<Admin />}/>} />
          <Route path="hr" element={<PrivateRoute element={<Hr />}/>} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
