import React from "react";
import Cookies from 'js-cookie';
import ProfileDetails from "../components/profile/ProfileDetails";
import RedirectWithDelay from '../components/Auth/redirect';


function Profile() {
  const user = Cookies.get('user');

  return (
    <>
      <div>
        {!user ?
        (
          <h2>No user logged in</h2>
        ):
        (
          <>
            {!navigator.onLine?
            (
              <RedirectWithDelay to="/offline" delay={0}/>
            ):
            (
              <ProfileDetails></ProfileDetails>
            )
            }
          </>
        )}
      </div>
    </>
  );
}

export default Profile;

/*
((userLogged.role==='admin') && (
            <ProfileAdmin></ProfileAdmin>
          ))||
          ((userLogged.role==='employee' || userLogged.role==='hr') && (
            <ProfileEmployee></ProfileEmployee>
          ))||
          ((userLogged.role==='client') && (
            <ProfileClient></ProfileClient>
          ))||
          ((userLogged.role==='user') && (
            <ProfileUser></ProfileUser>
          ))
*/