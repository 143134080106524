import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import http from "../http-common";
import Cookies from 'js-cookie';
import ApplicationCSS from "./Application.module.css";
import Logincss from "../components/Auth/login.module.css";
import { LatoFont } from '../fonts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFilePdf, faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import RedirectWithDelay from '../components/Auth/redirect';

  
function Application() {
  const { id }  = useParams();
  const [pos, setPos] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [applicationSent, setApplicationSent] = useState(false);
  const [error, setError] = useState("");
  const [userLogged, setUserLogged] = useState({});
  const tokenId = Cookies.get('user');
  const navigate = useNavigate();
  const navigationBack = Cookies.get('redirect');
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (!navigator.onLine) {
      navigate("/offline");
    }
    else{
      if(tokenId){
        const fetchUser = async () => {
          try {
            const token = JSON.parse(tokenId).token;
    
            if (!token) {
              return;
            }
    
            const response = await http.get('users/user', {
              headers: {
                Authorization: token,
              },
            });
            const userId = response.data.id;
            await http.get(`/users/id/${userId}`).then((res) =>{
                setUserLogged(res.data);
            });
          } catch (error) {
            console.error('Error fetching user details:', error.response.data.message);
          }
        };
        fetchUser();
        Cookies.remove('redirect');
        try{
          http.get(`/positions/${id}`).then((res) =>{
            setPos(res.data);
          });
        } catch (error) {
              console.error('Error fetching position:', error.response.data.message);
        }
      }
      else{
        Cookies.set("redirect", JSON.stringify({"redirection":`/application/${id}`}));
        navigate("/login");
      }
    }
  }, []);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleSubmit = async () =>{
    try {
      if (!navigator.onLine) {
        navigate("/offline");
      }
      else{
        setError("");
        await http.post(`/applications`, {applicant:userLogged._id, appliedFor:id}).then((res) =>{
            if(res.data.error){
              setError(res.data.error);
            }
            else{
              setApplicationSent(true);
            }
        });
      }
    } catch (error) {
      console.error('Error fetching user details:', error.response.data.message);
    }
  };
  const redirectPage = () => {
    if(navigationBack){
      navigate(JSON.parse(navigationBack).redirection);
    }
    else{
      navigate("/positions");
    }
  };
  const uploadCV = () => {
      Cookies.set("redirect", JSON.stringify({"redirection":`/application/${id}`}));
      navigate("/profile&change&cv");
  };
  return (
    <div className={`${ApplicationCSS.general}`}>
      {applicationSent===false?
      (
        <div className={`${ApplicationCSS.position}`}>
            <LatoFont>
            <h2 className={`${ApplicationCSS.heading_1}`}>{pos.title}</h2>
            <span className={`${ApplicationCSS.heading_2}`}>My Super Easy Application</span>
            <hr></hr><br></br>
            <p className={`${ApplicationCSS.span_1}`}>I am {userLogged.lastName} {userLogged.firstName}</p>
              <p>
                    {(userLogged.cv==="" || userLogged.cv===undefined)?
                    (
                      <>
                        <span className={`${ApplicationCSS.cvSpan}`}>No CV in my file yet</span>
                          <FontAwesomeIcon icon={faPenToSquare} className={`${ApplicationCSS.pen}`} onClick={uploadCV}/>
                          <span className={`${ApplicationCSS.cvMissing}`} onClick={uploadCV}> upload</span>
                      </>
                    ):
                    (
                      <>
                        <span className={`${ApplicationCSS.cvSpan}`}>CV is in my file</span>
                        <a href={`${process.env.REACT_APP_AWS_URL}/${userLogged.cv}`} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faFilePdf} className={`${ApplicationCSS.filePDF}`}/>
                        </a> 
                          <FontAwesomeIcon icon={faPenToSquare} className={`${ApplicationCSS.pen}`} onClick={uploadCV}/>
                          <span className={`${ApplicationCSS.cvChanging}`} onClick={uploadCV}> replace</span>
                      </>
                    )}
              </p><br></br>
              {error===""?
              (
                <p className={`${ApplicationCSS.error1}`}>*</p>
              ):
              (
                <p className={`${ApplicationCSS.error2}`}>{error}</p>
              )}
              <label style={{ display: 'flex', alignItems: 'center' }} className={`${ApplicationCSS.checkbox}`}>
                <input 
                  type="checkbox" 
                  className={`${ApplicationCSS.checkBoxBack}`}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span style={{ marginLeft: '8px' }} className={`${ApplicationCSS.checkText}`}>
                {isChecked===false? (<span style={{color:"red"}}>*</span>):(<>*</>)} I have read and agree to the Data Privacy Statement
                  <a href={`${process.env.REACT_APP_AWS_URL}/ujQhaJjuHf8ojfAk.pdf`} target="_blank" rel="noreferrer"> 
                    {isChecked===false? (<span className={`${ApplicationCSS.checkLinkOrange}`}> link</span>):(<span className={`${ApplicationCSS.checkLinkGrey}`}> link</span>)}
                  </a>
                </span>
              </label>
              
              {(isChecked===false || userLogged.cv==="" || userLogged.cv===undefined || error!=="")?
              (
                <button disabled className={`${ApplicationCSS.contactDisabled}`}>APPLY</button>
              ):
              (
                <button  className={`${ApplicationCSS.contact}`} onClick={handleSubmit}>APPLY</button>
              )}
              <br></br>
              <Link to="/positions" className={`${ApplicationCSS.backLink}`}>Back to positions</Link>
            </LatoFont>
          </div>  
      ):
      (
        <div className={`${Logincss.buttonSendDiv1}`} onClick={redirectPage}>
              <FontAwesomeIcon icon={faCheck} className={`${Logincss.v}`} size="xl"/>
              <p className={`${Logincss.login}`}>Successfully Applied</p>
              {navigationBack?
              (
                <RedirectWithDelay to={`${JSON.parse(navigationBack).redirection}`} delay={2000} />
              ):
              (
                <RedirectWithDelay to="/positions" delay={2000} />
              )}
              
        </div>
      )}
       
    </div>
  );
}

export default Application;