import React from "react";
import { useState, useEffect } from "react";
import http from "../http-common";
import Cookies from 'js-cookie';
import RedirectWithDelay from '../components/Auth/redirect';

function Hr() {
  const tokenId = Cookies.get('user');
  const [userLogged, setUserLogged] = useState({});
  const [role, setRole] = useState(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if(tokenId)
    {
      const fetchUser = async () => {
        try {
          const token = JSON.parse(tokenId).token;
  
          if (!token) {
            return;
          }
  
          const response = await http.get('users/user', {
            headers: {
              Authorization: token,
            },
          });
          const id = response.data.id;
          await http.get(`/users/id/${id}`).then((res) =>{
              setUserLogged(res.data);
              setRole(res.data.role);
          });
        } catch (error) {
          console.error('Error fetching user details:', error.response.data.message);
        }
      };
      fetchUser();
    }
  }, []);
    return (
      <>
        <div>
          {role===null  ?
          (
            <RedirectWithDelay to="/login" delay={2000} />
          ):
          (
            ((role==='hr') && (
            <>
                <h1>Hr Zone:</h1>
                <p>welcome: {userLogged.firstName} {userLogged.lastName}</p>
                <p>role: {role}</p>
            </>
            ))||
            ((role!=='hr') && (
              <RedirectWithDelay to="/" delay={1} />
            ))
          )}
        </div>
      </>
    );
}

export default Hr;