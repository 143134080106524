import React, { useState } from 'react';
import PopupCSS from "./PopupForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXmark, faCheck} from "@fortawesome/free-solid-svg-icons";
import { LatoFont, RobotoFont } from "../../fonts";
import http from "../../http-common";

const PopupForm = ({ handleClose, show }) => {
  const showHideClassName = show ? `${PopupCSS.modal} ${PopupCSS.displayBlock}` : `${PopupCSS.modal} ${PopupCSS.displayNone}`;

  const [formData, setFormData] = useState({
    rep: '',
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    subject: '',
    text: '',
  });
  const countText = formData.text.length;

  const [isValid, setIsValid] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidText, setIsValidText] = useState(true);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[a-zA-Z ]+$/;
  const textRegex = /^.{2,1000}$/s;

  const [confirm, setConfirm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChangeEmail = (e) => {
    setFormData({
      ...formData,
      companyEmail: e.target.value
    });
    setIsValid(emailRegex.test(e.target.value));
  };

  const handleChangeName = (e) => {
    setFormData({
      ...formData,
      rep: e.target.value
    });
    setIsValidName(nameRegex.test(e.target.value));
  };

  const handleChangeText = (e) => {
    setFormData({
      ...formData,
      text: e.target.value
    });
    setIsValidText(textRegex.test(e.target.value));
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if(formData.rep && formData.companyEmail && formData.text){
      http
        .post('emails/contact', formData)
        .then((res) => {
            console.log(res);
            setFormData({
              rep: '',
              companyName: '',
              companyEmail: '',
              companyPhone: '',
              subject: '',
              text: '',
            });
            setConfirm(true);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
        })
        .catch((err) => {
            console.log(err);
        });
    }
    else{
      if(formData.rep===''){
        setIsValidName(nameRegex.test(formData.rep));
      } 
      if(formData.companyEmail===''){
        setIsValid(emailRegex.test(formData.companyEmail));
      }
      if(formData.text===''){
        setIsValidText(textRegex.test(formData.text));
      }
    }
  };


  return (
    <div className={showHideClassName}>
        <div className={`${PopupCSS.bar}`}>
            <FontAwesomeIcon icon={faXmark} className={`${PopupCSS.x}`} onClick={handleClose}/>
        </div>
      <section className={`${PopupCSS.modalMain}`}>
        <LatoFont>
        {confirm === false?
        (
          <form onSubmit={handleSubmit} name="contact">
            <input
                className={isValidName===false? `${PopupCSS.inputError}`: `${PopupCSS.input}`}
                name="rep"
                placeholder="NAME*"
                maxLength="100"
                value={formData.rep}
                onChange={handleChangeName}
            />
            <input
                className={`${PopupCSS.input}`}
                name="companyName"
                placeholder="COMPANY"
                maxLength="100"
                value={formData.companyName}
                onChange={handleChange}
            />
            <input
                className={isValid===false? `${PopupCSS.inputError}`: `${PopupCSS.input}`}
                name="companyEmail"
                placeholder="EMAIL*"
                maxLength="100"
                value={formData.companyEmail}
                onChange={handleChangeEmail}
            />
            <input
                className={`${PopupCSS.input}`}
                name="companyPhone"
                placeholder="PHONE"
                maxLength="20"
                value={formData.companyPhone}
                onChange={handleChange}
            />
            <input
                className={`${PopupCSS.input}`}
                name="subject"
                placeholder="SUBJECT"
                maxLength="150"
                value={formData.subject}
                onChange={handleChange}
            />
            <textarea
                className={isValidText===false? `${PopupCSS.textareaError}`: `${PopupCSS.textarea}`}
                rows={3}
                name="text"
                placeholder="MESSAGE*"
                maxLength="1001"
                value={formData.text}
                onChange={handleChangeText}
            /><span className={`${PopupCSS.smallText}`}><b>{countText}</b> of max 1000 characters</span>
            <RobotoFont>
            <label style={{ display: 'flex', alignItems: 'center' }} className={`${PopupCSS.checkbox}`}>
              <input 
                type="checkbox" 
                name="agree"
                className={`${PopupCSS.checkBoxBack}`}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span style={{ marginLeft: '8px' }} className={`${PopupCSS.checkText}`}>
              {isChecked===false? (<span style={{color:"red"}}>*</span>):(<>*</>)} I allow Veleax to store and process my personal data
              </span>
            </label>
            <div className={`${PopupCSS.error}`}>
            {(isValid===false || isChecked===false || isValidName===false || isValidText===false)?
            (
              <p>* Please complete all required fields</p>
            ):
            (
              <p style={{color:"white"}}>.</p>
            )}
                
            </div>
            </RobotoFont>
            <div className={`${PopupCSS.buttonSendDiv}`}>
              {(isValid===false || isChecked===false || isValidName===false || isValidText===false)? 
              (
                <button disabled className={`${PopupCSS.buttonDisabled}`} type="submit">SEND</button>
              ):
              (
                <button className={`${PopupCSS.button}`} type="submit">SEND</button>
              )}
            </div>
          </form>
        ):(
          <div className={`${PopupCSS.buttonSendDiv}`}>
            <p className={`${PopupCSS.center}`}>Thank you for contacting us.<br></br>
                We will be in touch shortly!</p><br></br>
            <FontAwesomeIcon icon={faCheck} className={`${PopupCSS.v}`} size="xl"/>
          </div>
        )}
          </LatoFont>
        
      </section>
    </div>
  );
};

export default PopupForm;
