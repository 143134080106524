import React from "react";
import TechCSS from "./Tech.module.css";
import {RobotoFont, LatoFont} from '../../fonts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Azure from "../../images/home/azure.png";
import Django from "../../images/home/django.png";
import Mongo from "../../images/home/mongodb.png";
import MySQL from "../../images/home/mysql.png";
import PostgreSQL from "../../images/home/postgres.png";
import SpringBoot from "../../images/home/sprinboot.png";
import Ts from "../../images/home/ts_1.png";
import Photoshop from "../../images/home/photoshop.png";
import Illustrator from "../../images/home/illustrator.png";
import Indesign from "../../images/home/indesign.png";
import Xd from "../../images/home/xd.png";
import MsqlServer from "../../images/home/msqlserver.png";
import {
  faReact, faAngular, faBootstrap, faCss3Alt, faHtml5,
  faAws, faJs, faNodeJs, faPython, faJava, faPhp, faFigma,
  faSass
 } from "@fortawesome/free-brands-svg-icons";

function Tech() {
  return (
    <>
      <div className={TechCSS.bodyBackground}>
        <div className={`container w-100 ${TechCSS.center1}`}>
          <RobotoFont><p className={`${TechCSS.heading}`}>Tech Stack</p></RobotoFont>
          <LatoFont><p className={`${TechCSS.slogan}`}>"From User Interface to Server-Side Brilliance: Full Stack Prevails!"</p></LatoFont>
        </div>   
        <div className={`w-100 ${TechCSS.center} ${TechCSS.back}`}>
          <RobotoFont>
            <h6 className={`${TechCSS.smallHeading}`}>DESIGN</h6>
          </RobotoFont>
            <p>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faFigma} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Figma</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <img src={Photoshop} alt="photoshop" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Photoshop</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <img src={Illustrator} alt="Illustrator" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Illustrator</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <img src={Indesign} alt="Indesign" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>InDesign</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`} style={{paddingRight:0}}>
                <img src={Xd} alt="XD" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>XD</span></LatoFont>
              </span>
            </p>
        </div>
        <div className={`w-100 ${TechCSS.center}`}>
          <RobotoFont>
            <h6 className={`${TechCSS.smallHeading}`}>FRONT-END</h6>
          </RobotoFont>
            <p>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faReact} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>React</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faAngular} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>Angular</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <img src={Ts} alt="typescript" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>TypeScript</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faBootstrap} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>Bootstrap</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faCss3Alt} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>CSS3</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faHtml5} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>HTML5</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
              <FontAwesomeIcon icon={faSass} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>Sass</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`} style={{paddingRight:0}}>
                <FontAwesomeIcon icon={faJs} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>JavaScript</span></LatoFont>
              </span> 
            </p>
        </div>
        <div className={`w-100 ${TechCSS.center} ${TechCSS.back}`}>
          <RobotoFont>
            <h6 className={`${TechCSS.smallHeading}`}>BACK-END</h6>
          </RobotoFont>
            <p>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faNodeJs} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Node.js</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faPython} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Python</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <img src={Django} alt="django" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Django</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <img src={SpringBoot} alt="springboot" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Spring<span style={{color: "rgb(248, 245, 245)"}}>_</span>Boot</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faPhp} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>PHP</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`} style={{paddingRight:0}}>
                <FontAwesomeIcon icon={faJava} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Java</span></LatoFont>
              </span>
            </p>
        </div>
        <div className={`w-100 ${TechCSS.center}`}>
          <RobotoFont>
            <h6 className={`${TechCSS.smallHeading}`}>DATABASES</h6>
          </RobotoFont>
            <p>
              <span className={`${TechCSS.icons}`}>
                <img src={PostgreSQL} alt="postgresql" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>PostgreSQL</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <img src={MySQL} alt="mysql" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>MySQL</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`}>
                <img src={Mongo} alt="mongodb" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>MongoDB</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`} style={{paddingRight:0}}>
                <img src={MsqlServer} alt="sqlServer" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons1}`}>Microsoft<span style={{color: "white"}}>_</span>SQL<span style={{color: "white"}}>_</span>Server</span></LatoFont>
              </span> 
            </p>
        </div>
        <div className={`w-100 ${TechCSS.center} ${TechCSS.back}`}>
          <RobotoFont>
            <h6 className={`${TechCSS.smallHeading}`}>CLOUDS</h6>
          </RobotoFont>
            <p>
              <span className={`${TechCSS.icons}`}>
                <FontAwesomeIcon icon={faAws} size="2xl"/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Amazon<span style={{color: "rgb(248, 245, 245)"}}>_</span>Web<span style={{color: "rgb(248, 245, 245)"}}>_</span>Services</span></LatoFont>
              </span>
              <span className={`${TechCSS.icons}`} style={{paddingRight:0}}>
                <img src={Azure} alt="azure" className={`${TechCSS.image}`}/><br></br>
                <LatoFont><span className={`${TechCSS.icons2}`}>Microsoft<span style={{color: "rgb(248, 245, 245)"}}>_</span>Azure</span></LatoFont>
              </span>
            </p>
        </div>
      </div>
    </>
  );
}

export default Tech;