import React, { useState } from "react";
import Logincss from "./login.module.css";
import http from "../../http-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { LatoFont, RobotoFont } from "../../fonts";
import RedirectWithDelay from './redirect';
import { useNavigate } from "react-router-dom";


function Reset() {
    const [formData, setFormData] = useState({
        email: ''
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [error, setError] = useState("");
    const [confirm, setConfirm] = useState(false);
    const navigate = useNavigate();

    const handleChangeEmail = (e) => {
        setFormData({
          ...formData,
          email: e.target.value
        });
        setIsEmailValid(emailRegex.test(e.target.value));
        setError("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          if (!navigator.onLine) {
            navigate("/offline");
          }
          else{
            await http.post('users/reset', formData).then((response) =>{
                if(response.data.error){
                  setError(response.data.error);
                }
                else{
                  setError('');
                  setFormData({
                    email: ''
                  });
                  setIsEmailValid(false);
                  setConfirm(true);
                } 
            });
          }
        } catch (error) {
          console.log("nothing happened");
          setError(error);
        }
      };

  return (
    <div className={`${Logincss.modal}`}>
        <div className={`${Logincss.divSection2}`} style={{textAlign: "center", padding: "50px 20px 0px 20px"}}>
        <section className={`${Logincss.section2}`}>
          {(confirm===false)?
          (
            <form onSubmit={handleSubmit} name="reset">
            <LatoFont>
              <input
                  className={isEmailValid===false? `${Logincss.inputError}`: `${Logincss.input}`}
                  name="email"
                  placeholder="EMAIL*"
                  maxLength="100"
                  value={formData.email}
                  onChange={handleChangeEmail}
              /><br></br>
              </LatoFont>
            <RobotoFont>
              <div className={`${Logincss.error}`} style={{textAlign: "center", margin:"0px"}}>
              {(isEmailValid===false)?
              (
                <p>* Enter your email correctly</p>
              ):
              (
                <p>{error}<span style={{color: "#191b23"}}>.</span></p>
              )}
                  
              </div>
            </RobotoFont>
            <LatoFont>
              <div className={`${Logincss.buttonSendDiv}`} style={{textAlign: "center", marginTop:"40px", padding:"0px"}}>
                {(isEmailValid===false || formData.email === '')? 
                (
                  <button disabled className={`${Logincss.buttonDisabled}`} style={{textAlign: "center", margin:"0px"}} type="submit">RESET PASSWORD LINK</button>
                ):
                (
                  <button className={`${Logincss.button}`} style={{textAlign: "center", margin:"0px"}} type="submit">RESET PASSWORD LINK</button>
                )}
              </div>
            </LatoFont>
        </form>
        ):(
            <LatoFont>
            <div className={`${Logincss.buttonSendDiv1}`}>
              <FontAwesomeIcon icon={faCheck} className={`${Logincss.v}`} size="xl"/>
              <p className={`${Logincss.login}`}>Reset link has been sent</p>
              <RedirectWithDelay to="/" delay={2000} />
            </div>
            </LatoFont>
          )}
        </section>
      </div>     
    </div>
  );
}

export default Reset;

