// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileImg_basicDiv__9tdv5{
    width: 100%;
    height: 500px;
    position: absolute;
    padding-bottom: 100px;
}
.ProfileImg_firstDiv__KiPAN{
    width: 100%;
    height: 250px;
    background-color: #43454f;
    text-align: center;
}
.ProfileImg_secondDiv__g7v2s{
    width: 100%;
    height: 250px;
    background-color: #191b23;
    text-align: center;
}
.ProfileImg_imgDiv__ogJmw{
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ProfileImg_uploadButton__i\\+TZ5{
    margin-top: 0px;
    background-color: #191b23;
    padding:5px 15px 5px 15px;
    /*border: 2px solid #f3530e;*/
    border-radius: 8px;
    color:rgb(229, 227, 227);
}
.ProfileImg_uploadButton__i\\+TZ5:hover{
    color: rgb(115, 114, 114);
    border: 2px solid #43454f;
}
.ProfileImg_customfileupload__E-PJi{
    display: inline-block;
    font-family: Arial, sans-serif;
}
input[type="file"] {
    display: none;
}
.ProfileImg_error__-w8s7{
    margin-top: 115px;
    color: #f3530e;
    font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/ProfileImg.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,cAAc;IACd,SAAS;IACT,kBAAkB;IAClB,QAAQ;IACR,SAAS;IAET,gCAAgC;AACpC;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,wBAAwB;AAC5B;AACA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;AACA;IACI,qBAAqB;IACrB,8BAA8B;AAClC;AACA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".basicDiv{\n    width: 100%;\n    height: 500px;\n    position: absolute;\n    padding-bottom: 100px;\n}\n.firstDiv{\n    width: 100%;\n    height: 250px;\n    background-color: #43454f;\n    text-align: center;\n}\n.secondDiv{\n    width: 100%;\n    height: 250px;\n    background-color: #191b23;\n    text-align: center;\n}\n.imgDiv{\n    border-radius: 50%;\n    width: 200px;\n    height: 200px;\n    display: block;\n    margin: 0;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -ms-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n}\n.uploadButton{\n    margin-top: 0px;\n    background-color: #191b23;\n    padding:5px 15px 5px 15px;\n    /*border: 2px solid #f3530e;*/\n    border-radius: 8px;\n    color:rgb(229, 227, 227);\n}\n.uploadButton:hover{\n    color: rgb(115, 114, 114);\n    border: 2px solid #43454f;\n}\n.customfileupload{\n    display: inline-block;\n    font-family: Arial, sans-serif;\n}\ninput[type=\"file\"] {\n    display: none;\n}\n.error{\n    margin-top: 115px;\n    color: #f3530e;\n    font-size: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"basicDiv": `ProfileImg_basicDiv__9tdv5`,
	"firstDiv": `ProfileImg_firstDiv__KiPAN`,
	"secondDiv": `ProfileImg_secondDiv__g7v2s`,
	"imgDiv": `ProfileImg_imgDiv__ogJmw`,
	"uploadButton": `ProfileImg_uploadButton__i+TZ5`,
	"customfileupload": `ProfileImg_customfileupload__E-PJi`,
	"error": `ProfileImg_error__-w8s7`
};
export default ___CSS_LOADER_EXPORT___;
