import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import http from "../http-common";
import PositionCSS from "./Position.module.css";
import {LatoFont} from '../fonts';
import { useNavigate } from "react-router-dom";
  
function Training() {
  const { id }  = useParams();
  const [pos, setPos] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    try{
      if (!navigator.onLine) {
        navigate("/offline");
      }
      else{
        http.get(`/trainings/${id}`).then((res) =>{
          setPos(res.data);
        });
      }
    } catch (error) {
          console.error('Error fetching course:', error.response.data.message);
    }
  }, []);
  return (
    <div className={`${PositionCSS.general}`}>
          <div className={`${PositionCSS.position}`}>
            <LatoFont>
            <h2 className={`${PositionCSS.heading_1}`}>{pos.title}</h2>
            <p className={`${PositionCSS.p_1}`}>{pos.place}<br></br></p>
            <p className={`${PositionCSS.overview}`}> 
                {pos.overview}
            </p>
            <Link to="/contact" className={PositionCSS.navigationContact}>
              <button  className={`${PositionCSS.contact}`}>
                JOIN US
              </button>
            </Link>
            </LatoFont>
          </div>   
    </div>
  );
}

export default Training;