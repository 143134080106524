import React from "react";
import { useState } from "react";
import WebCSS from "./Web.module.css";
import {RobotoFont, LatoFont} from '../../fonts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

function Web() {
  const[one, setOne]=useState(false);
  const[two, setTwo]=useState(false);
  const[three, setThree]=useState(false);
  const[four, setFour]=useState(false);
  const[five, setFive]=useState(false);
  const[six, setSix]=useState(false);
  const[seven, setSeven]=useState(false);

  function showText(figure){
    switch(figure) {
      case 1:
        if(one===false){
          setOne(true);
        }
        else{
          setOne(false)
        };
        break;
      case 2:
        if(two===false){
          setTwo(true);
        }
        else{
          setTwo(false)
        };
        break;
      case 3:
        if(three===false){
          setThree(true);
        }
        else{
          setThree(false)
        };
        break;
      case 4:
        if(four===false){
          setFour(true);
        }
        else{
          setFour(false)
        };
        break;
      case 5:
        if(five===false){
          setFive(true);
        }
        else{
          setFive(false)
        };
        break;
      case 6:
        if(six===false){
          setSix(true);
        }
        else{
          setSix(false)
        };
        break;
      case 7:
        if(seven===false){
          setSeven(true);
        }
        else{
          setSeven(false)
        };
        break;
      default:
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(false);
        setSix(false);
        setSeven(false);
    }
  }
  return (
    <>
      <div className={`${WebCSS.container}`} id="web">
        <div className={`${WebCSS.titleContainer}`}>
          <RobotoFont>
            <p className={`${WebCSS.title}`}>Web Development</p>
          </RobotoFont>
        </div>
        <div className={`${WebCSS.overview}`}>
        <LatoFont>
            <p className={`${WebCSS.text}`}>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(1)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${one? WebCSS.signOpen : WebCSS.sign}`}/> Full-Stack Development
              </a><br></br>
              <p className={`${one? WebCSS.subText : WebCSS.subTextNo}`}>
                  Combining front-end and back-end development skills to work on both client and server sides.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(2)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${two? WebCSS.signOpen : WebCSS.sign}`}/> API Development
              </a><br></br>
              <p className={`${two? WebCSS.subText : WebCSS.subTextNo}`}>
                  Creating RESTful APIs to enable communication between the front-end and back-end systems.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(3)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${three? WebCSS.signOpen : WebCSS.sign}`}/> E-commerce Development
              </a><br></br>
              <p className={`${three? WebCSS.subText : WebCSS.subTextNo}`}>
                  Utilizing popular e-commerce platforms like Magento, Shopify, WooCommerce (for WordPress), 
                  BigCommerce, etc., depending on the specific requirements of the client.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(4)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${four? WebCSS.signOpen : WebCSS.sign}`}/> Performance Optimization
              </a><br></br>
              <p className={`${four? WebCSS.subText : WebCSS.subTextNo}`}>
                  Improving website speed and performance through techniques like code splitting, lazy loading, and efficient asset management.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(5)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${five? WebCSS.signOpen : WebCSS.sign}`}/> Testing and Quality Assurance
              </a><br></br>
              <p className={`${five? WebCSS.subText : WebCSS.subTextNo}`}>
                  Conducting unit testing, integration testing, and end-to-end testing to ensure the reliability and functionality of the code.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(6)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${six? WebCSS.signOpen : WebCSS.sign}`}/> DevOps Practices
              </a><br></br>
              <p className={`${six? WebCSS.subText : WebCSS.subTextNo}`}>
                  Implementing continuous integration and continuous deployment pipelines for efficient and automated development workflows.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(7)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${seven? WebCSS.signOpen : WebCSS.sign}`}/> Web Security
              </a><br></br>
              <p className={`${seven? WebCSS.subText : WebCSS.subTextNo}`}>
                  Web security practices (HTTPS, encryption, secure authentication).
              </p>
            </p>
          </LatoFont>
        </div>
      </div>
    </>
  );
}

export default Web;