// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileCV_imgDiv__F1JJV{
    background-color: rgb(229, 227, 227);
    border-radius: 5px;
    border: 2px solid black;
    width: 350px;
    height: 300px;
    display: block;
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    overflow-x: hidden;
}
.ProfileCV_pdfDiv__ShtDn{
    transform: scale(0.57);
    transform-origin: top left;
}

/*customized scrollbar*/
.ProfileCV_imgDiv__F1JJV::-webkit-scrollbar{
    width:0px;
}
.ProfileCV_imgDiv__F1JJV::-webkit-scrollbar-thumb{
    background: black;  
}
.ProfileCV_imgDiv__F1JJV::-webkit-scrollbar-thumb:hover{
    background: #8f8d8d; 
}
@media only screen and (max-width: 350px) {
    .ProfileCV_imgDiv__F1JJV{
        width: 250px;
        height: 250px;
        overflow-x: auto;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/profile/ProfileCV.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,cAAc;IACd,SAAS;IACT,kBAAkB;IAClB,QAAQ;IACR,SAAS;IAET,gCAAgC;IAChC,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA,uBAAuB;AACvB;IACI,SAAS;AACb;AACA;IACI,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB;AACA;IACI;QACI,YAAY;QACZ,aAAa;QACb,gBAAgB;IACpB;AACJ","sourcesContent":[".imgDiv{\n    background-color: rgb(229, 227, 227);\n    border-radius: 5px;\n    border: 2px solid black;\n    width: 350px;\n    height: 300px;\n    display: block;\n    margin: 0;\n    position: absolute;\n    top: 40%;\n    left: 50%;\n    -ms-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n.pdfDiv{\n    transform: scale(0.57);\n    transform-origin: top left;\n}\n\n/*customized scrollbar*/\n.imgDiv::-webkit-scrollbar{\n    width:0px;\n}\n.imgDiv::-webkit-scrollbar-thumb{\n    background: black;  \n}\n.imgDiv::-webkit-scrollbar-thumb:hover{\n    background: #8f8d8d; \n}\n@media only screen and (max-width: 350px) {\n    .imgDiv{\n        width: 250px;\n        height: 250px;\n        overflow-x: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgDiv": `ProfileCV_imgDiv__F1JJV`,
	"pdfDiv": `ProfileCV_pdfDiv__ShtDn`
};
export default ___CSS_LOADER_EXPORT___;
