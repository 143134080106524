import React from "react";
import ProfCSS from "./Professional.module.css";
import {RobotoFont, LatoFont} from '../../fonts';

function Professional() {
  return (
    <>
      <div className={ProfCSS.bodyBackground}>
        <div className={ProfCSS.application}>
            <RobotoFont>
              <p>
                Our Professional<br></br> Development Team
              </p>
            </RobotoFont>
        </div>
        <div className={ProfCSS.deviz}>
          <LatoFont>
            <p> 
              Elevating development excellence with Us
            </p>
          </LatoFont>
        </div>
        <div className={`${ProfCSS.about}`}>
          <div className={`${ProfCSS.about1}`}>
            <LatoFont>
            <p>
                In an ever-evolving technological landscape, having a reliable and 
                proficient development team is paramount. At VELEAX, 
                our professional development team stand ready to tackle the most 
                demanding challenges, armed with expertise, innovation, and a commitment 
                to excellence. Together, we embark on a journey towards realizing your 
                vision and driving your success.
            </p>
            </LatoFont>
          </div>
          <div className={`${ProfCSS.about2}`}>
            <LatoFont>
            <p>
                We understand that every client is unique, with their own set of 
                challenges and goals. Our professional development team approach each 
                project with a client-centric mindset, ensuring that we not only meet 
                but exceed expectations. This client-focused approach has been instrumental 
                in establishing long-lasting partnerships and a track record of successful 
                project deliveries.
            </p>
            </LatoFont>
          </div> 
        </div>
      </div>
    </>
  );
}

export default Professional;