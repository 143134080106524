import React from "react";
import CarouselCSS from "./Carousel.module.css";
import { Link } from "react-router-dom";
import {RobotoFont, LatoFont} from '../../fonts';
import Web from "../../images/home/web_dev_orange.png";
import Training from "../../images/home/training_orange.png";
import Custom from "../../images/home/softwear_en_orange.png";
import Brand from "../../images/home/brand.png";
import Design from "../../images/home/design.png";

function Carousel() {
  return (
    <div id="carouselExampleDark" className={`carousel carousel-dark slide ${CarouselCSS.paddingBottom}`} data-bs-ride="carousel">
        <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5"></button>
        </div>
        <div className="carousel-inner">
            <Link to="/services" className={CarouselCSS.link}>
                <div className={`carousel-item active ${CarouselCSS.padding} ${CarouselCSS.center}`} data-bs-interval="3000">
                    <div className={`d-block w-100 ${CarouselCSS.centerImg}`}>
                        <img src={Web}  alt="web development" className={`${CarouselCSS.img}`}/>
                    </div>
                    <div className={`d-block w-100 ${CarouselCSS.center}`}>
                        <RobotoFont>
                            <h3 className={`${CarouselCSS.heading}`}>Web Development</h3>
                        </RobotoFont>
                    </div>
                    <div className={`container w-100`}>
                        <div className={`container ${CarouselCSS.leftText}`}>
                        </div>
                        <div className={`container ${CarouselCSS.centerText}`}>
                            <LatoFont>
                                <p className={`${CarouselCSS.p}`}>
                                    We create dynamic, interactive, and functional web applications that run on web browsers. 
                                    These applications can perform a wide range of tasks, from processing forms to providing complex services or tools.
                                </p>
                            </LatoFont>
                        </div>
                        <div className={`container ${CarouselCSS.rightText}`}>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/services" className={CarouselCSS.link}>
                <div className={`carousel-item ${CarouselCSS.padding} ${CarouselCSS.center}`} data-bs-interval="3000">
                    <div className={`d-block w-100 ${CarouselCSS.centerImg}`}>
                        <img src={Design}  alt="web development" className={`${CarouselCSS.img}`}/>
                    </div>
                    <div className={`d-block w-100 ${CarouselCSS.center}`}>
                        <RobotoFont>
                            <h3 className={`${CarouselCSS.heading}`}>U<span className={`${CarouselCSS.ui}`}>X/</span>UI Design</h3>
                        </RobotoFont>
                    </div>
                    <div className={`container w-100`}>
                        <div className={`container ${CarouselCSS.leftText}`}>
                        </div>
                        <div className={`container ${CarouselCSS.centerText}`}>
                            <LatoFont>
                                <p className={`${CarouselCSS.p}`}>
                                    UX/UI Design services are essential components of web and application development. 
                                    We focus on creating an intuitive, engaging, and aesthetically pleasing user experience UX 
                                    and user interface UI. 
                                </p>
                            </LatoFont>
                        </div>
                        <div className={`container ${CarouselCSS.rightText}`}>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/services" className={CarouselCSS.link}>
                <div className={`carousel-item ${CarouselCSS.padding} ${CarouselCSS.center}`} data-bs-interval="3000">
                    <div className={`d-block w-100 ${CarouselCSS.centerImg}`}>
                        <img src={Custom}  alt="web development" className={`${CarouselCSS.img}`}/>
                    </div>
                    <div className={`d-block w-100 ${CarouselCSS.center}`}>
                        <RobotoFont>
                            <h3 className={`${CarouselCSS.heading}`}>Custom Apps Development</h3>
                        </RobotoFont>
                    </div>
                    <div className={`container w-100`}>
                        <div className={`container ${CarouselCSS.leftText}`}>
                        </div>
                        <div className={`container ${CarouselCSS.centerText}`}>
                            <LatoFont>
                                <p className={`${CarouselCSS.p}`}>
                                    We create applications from scratch based on your specifications. 
                                    This involves coding using various technologies to achieve the desired functionality and design.
                                </p>
                            </LatoFont>
                        </div>
                        <div className={`container ${CarouselCSS.rightText}`}>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/services" className={CarouselCSS.link}>
                <div className={`carousel-item ${CarouselCSS.padding} ${CarouselCSS.center}`} data-bs-interval="3000">
                    <div className={`d-block w-100 ${CarouselCSS.centerImg}`}>
                        <img src={Brand}  alt="web development" className={`${CarouselCSS.img}`}/>
                    </div>
                    <div className={`d-block w-100 ${CarouselCSS.center}`}>
                        <RobotoFont>
                            <h3 className={`${CarouselCSS.heading}`}>Branding</h3>
                        </RobotoFont>
                    </div>
                    <div className={`container w-100`}>
                        <div className={`container ${CarouselCSS.leftText}`}>
                        </div>
                        <div className={`container ${CarouselCSS.centerText}`}>
                            <LatoFont>
                                <p className={`${CarouselCSS.p}`}>
                                    Our Branding services encompass a range of activities that help businesses establish 
                                    a strong and memorable brand identity. This includes crafting a unique visual and verbal identity, 
                                    establishing a brand strategy, and creating consistent messaging across various platforms.
                                </p>
                            </LatoFont>
                        </div>
                        <div className={`container ${CarouselCSS.rightText}`}>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/services" className={CarouselCSS.link}>
                <div className={`carousel-item ${CarouselCSS.padding} ${CarouselCSS.center}`} data-bs-interval="3000">
                    <div className={`d-block w-100 ${CarouselCSS.centerImg}`}>
                        <img src={Training}  alt="web development" className={`${CarouselCSS.img}`}/>
                    </div>
                    <div className={`d-block w-100 ${CarouselCSS.center}`}>
                        <RobotoFont>
                            <h3 className={`${CarouselCSS.heading}`}>Corporate Training</h3>
                        </RobotoFont>
                    </div>
                    <div className={`container w-100`}>
                        <div className={`container ${CarouselCSS.leftText}`}>
                        </div>
                        <div className={`container ${CarouselCSS.centerText}`}>
                            <LatoFont>
                                <p className={`${CarouselCSS.p}`}>
                                    Our training services focus on equipping individuals with the skills, 
                                    knowledge, and tools they need to grow personally and professionally. 
                                    These services cover a wide range of areas, from personal development and soft skills 
                                    to technical and career-oriented training.
                                </p>
                            </LatoFont>
                        </div>
                        <div className={`container ${CarouselCSS.rightText}`}>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
  );
}

export default Carousel;





