// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_copyRight__goy05{
    background-color: rgba(0, 0, 0, 0.2); 
    color: rgb(229, 227, 227);
    font-size: 12px;
    width:100%;
}
.Footer_backgroundFooter__Pfj2w{
    background-color: #000002;
    margin: 0px;
    padding:0px;
    width: 100%;
}
.Footer_social__6J-nV{
    color: rgb(229, 227, 227);
}
.Footer_social__6J-nV:hover{
    color: rgb(115, 114, 114);
}
.Footer_navLogo__x2VX\\+{
    margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,eAAe;IACf,UAAU;AACd;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,WAAW;IACX,WAAW;AACf;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".copyRight{\n    background-color: rgba(0, 0, 0, 0.2); \n    color: rgb(229, 227, 227);\n    font-size: 12px;\n    width:100%;\n}\n.backgroundFooter{\n    background-color: #000002;\n    margin: 0px;\n    padding:0px;\n    width: 100%;\n}\n.social{\n    color: rgb(229, 227, 227);\n}\n.social:hover{\n    color: rgb(115, 114, 114);\n}\n.navLogo{\n    margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyRight": `Footer_copyRight__goy05`,
	"backgroundFooter": `Footer_backgroundFooter__Pfj2w`,
	"social": `Footer_social__6J-nV`,
	"navLogo": `Footer_navLogo__x2VX+`
};
export default ___CSS_LOADER_EXPORT___;
