import { Link } from "react-router-dom";
import Veleax from "../../images/logo/veleax_orange.png";
import FooCSS from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF, faXTwitter, faYoutube, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import { LatoFont } from "../../fonts";
import Contact from "./Contact"

function Footer() {
  return (
    <>
    <Contact></Contact>
    <div>
        <div className={`container-fluid ${FooCSS.backgroundFooter}`}>
            <div className="text-center border-bottom border-body" style={{backgroundColor:"#191b23"}}>
                <Link to="/" className="navbar-brand"><img src={Veleax} alt="logo" height="100" className={`${FooCSS.navLogo}`}/></Link>
                <div className="container d-flex justify-content-center py-5">
                    {/*
                    <a href="https://www.facebook.com/">
                        <FontAwesomeIcon icon={faFacebookF} size="xl" className={`btn-floating mx-3 ${FooCSS.social}`}/>
                    </a>
                    */}
                    <a href="https://twitter.com/Veleax_com">
                        <FontAwesomeIcon icon={faXTwitter} size="xl" className={`btn-floating mx-3 ${FooCSS.social}`}/>
                    </a>
                    {/*
                    <a href="https://www.youtube.com/">
                        <FontAwesomeIcon icon={faYoutube} size="xl" className={`btn-floating mx-3 ${FooCSS.social}`}/>
                    </a>
                    */}
                    <a href="https://www.linkedin.com/company/veleax/">
                        <FontAwesomeIcon icon={faLinkedinIn} size="xl" className={`btn-floating mx-3 ${FooCSS.social}`}/>
                    </a>
                </div>

                <div className={`text-center p-3 ${FooCSS.copyRight}`}>
                 <LatoFont>
                  <span>
                    © 2023 Copyright - Veleax Inc - All rights reserved
                  </span>
                 </LatoFont>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default Footer;
