import React, { useState, useEffect } from "react";
import http from "../http-common";
import ContactCSS from "./Contact.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { LatoFont, RobotoFont } from "../fonts";
import { useNavigate } from "react-router-dom";

function Contact() {
  const [formData, setFormData] = useState({
    rep: '',
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    subject: '',
    text: '',
  });
  const countText = formData.text.length;

  const [isValid, setIsValid] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidText, setIsValidText] = useState(true);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[a-zA-Z ]+$/;
  const textRegex = /^.{2,1000}$/s;
  const navigate = useNavigate();

  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChangeEmail = (e) => {
    setFormData({
      ...formData,
      companyEmail: e.target.value
    });
    setIsValid(emailRegex.test(e.target.value));
  };

  const handleChangeName = (e) => {
    setFormData({
      ...formData,
      rep: e.target.value
    });
    setIsValidName(nameRegex.test(e.target.value));
  };

  const handleChangeText = (e) => {
    setFormData({
      ...formData,
      text: e.target.value
    });
    setIsValidText(textRegex.test(e.target.value));
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  
  const handleSubmit = (e) => {
    if (!navigator.onLine) {
      navigate("/offline");
    }
    else{
      e.preventDefault();
      if(formData.rep && formData.companyEmail && formData.text){
        http
          .post('emails/contact', formData)
          .then((res) => {
              console.log(res);
              setFormData({
                rep: '',
                companyName: '',
                companyEmail: '',
                companyPhone: '',
                subject: '',
                text: '',
              });
              setConfirm(true);
          })
          .catch((err) => {
              console.log(err);
          });
      }
      else{
        if(formData.rep===''){
          setIsValidName(nameRegex.test(formData.rep));
        } 
        if(formData.companyEmail===''){
          setIsValid(emailRegex.test(formData.companyEmail));
        }
        if(formData.text===''){
          setIsValidText(textRegex.test(formData.text));
        }
      }
    }
  };
  return (
    <div className={`${ContactCSS.modal}`}>
      <div className={`${ContactCSS.divSection1}`}>
        <section className={`${ContactCSS.section1}`}>
          <RobotoFont>
            <p className={`${ContactCSS.heading}`}>
              Let's turn your <span className={`${ContactCSS.headingIdea}`}>idea </span><br></br>
              into a beautifully <br></br>functional <span className={`${ContactCSS.headingIdea}`}>reality</span> !
            </p>
          </RobotoFont>
          <LatoFont>
            <p className={`${ContactCSS.text}`}>
              Share your idea with us and we will reach you out to discuss it <br></br>and provide you with an estimate.
            </p>
          </LatoFont>
        </section>
      </div>
      <div className={`${ContactCSS.divSection2}`}>
        <section className={`${ContactCSS.section2}`}>
          {confirm === false?
          (
            <form onSubmit={handleSubmit} name="contactUs">
              {/*<RobotoFont><p className={`${ContactCSS.formHeading}`}>Contact us</p></RobotoFont>*/}
              <LatoFont>
              <input
                  className={isValidName===false? `${ContactCSS.inputError}`: `${ContactCSS.input}`}
                  name="rep"
                  placeholder="NAME*"
                  maxLength="100"
                  value={formData.rep}
                  onChange={handleChangeName}
              />
              <input
                  className={`${ContactCSS.input}`}
                  name="companyName"
                  placeholder="COMPANY"
                  maxLength="100"
                  value={formData.companyName}
                  onChange={handleChange}
              />
              <input
                  className={isValid===false? `${ContactCSS.inputError}`: `${ContactCSS.input}`}
                  name="companyEmail"
                  placeholder="EMAIL*"
                  maxLength="100"
                  value={formData.companyEmail}
                  onChange={handleChangeEmail}
              />
              <input
                  className={`${ContactCSS.input}`}
                  name="companyPhone"
                  placeholder="PHONE"
                  maxLength="20"
                  value={formData.companyPhone}
                  onChange={handleChange}
              />
              <input
                  className={`${ContactCSS.input}`}
                  name="subject"
                  placeholder="SUBJECT"
                  maxLength="150"
                  value={formData.subject}
                  onChange={handleChange}
              />
              <textarea
                  className={isValidText===false? `${ContactCSS.textareaError}`: `${ContactCSS.textarea}`}
                  rows={3}
                  name="text"
                  placeholder="MESSAGE*"
                  maxLength="1001"
                  value={formData.text}
                  onChange={handleChangeText}
              /><span className={`${ContactCSS.smallText}`}><b>{countText}</b> of max 1000 characters</span>
              </LatoFont>
              <RobotoFont>
              <label style={{ display: 'flex', alignItems: 'center' }} className={`${ContactCSS.checkbox}`}>
                <input 
                  type="checkbox" 
                  className={`${ContactCSS.checkBoxBack}`}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span style={{ marginLeft: '8px' }} className={`${ContactCSS.checkText}`}>
                {isChecked===false? (<span style={{color:"#f3530e"}}>*</span>):(<>*</>)} I allow Veleax to store and process my personal data
                </span>
              </label>
              <div className={`${ContactCSS.error}`}>
              {(isValid===false || isChecked===false || isValidName===false || isValidText===false)?
              (
                <p>* Please complete all required fields</p>
              ):
              (
                <p style={{color:"#191b23"}}>.</p>
              )}
                  
              </div>
              </RobotoFont>
              <LatoFont>
              <div className={`${ContactCSS.buttonSendDiv}`}>
                {(isValid===false || isChecked===false || isValidName===false || isValidText===false)? 
                (
                  <button disabled className={`${ContactCSS.buttonDisabled}`} type="submit">SEND</button>
                ):
                (
                  <button className={`${ContactCSS.button}`} type="submit">SEND</button>
                )}
              </div>
              </LatoFont>
            </form>
          ):(
            <LatoFont>
            <div className={`${ContactCSS.buttonSendDiv}`}>
              <p className={`${ContactCSS.center}`}>Thank you for contacting us.<br></br>
                  We will be in touch shortly!</p><br></br>
              <FontAwesomeIcon icon={faCheck} className={`${ContactCSS.v}`} size="xl"/>
            </div>
            </LatoFont>
          )}
        </section>
      </div> 
    </div>
  );
}
export default Contact;
