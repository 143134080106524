import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import http from "../http-common";
import PositionCSS from "./Position.module.css";
import {LatoFont} from '../fonts';
import { useNavigate } from "react-router-dom";
  
function Position() {
  const { id }  = useParams();
  const [pos, setPos] = useState({});
  const [skills, setSkills] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    try{
      if (!navigator.onLine) {
        navigate("/offline");
      }
      else{
        http.get(`/positions/${id}`).then((res) =>{
          setPos(res.data);
          setSkills(res.data.skills);
        });
      }
    } catch (error) {
          console.error('Error fetching position:', error.response.data.message);
    }
  }, []);
  return (
    <div className={`${PositionCSS.general}`}>
          <div className={`${PositionCSS.position}`}>
            <LatoFont>
            <h2 className={`${PositionCSS.heading_1}`}>{pos.title}</h2>
            <p className={`${PositionCSS.p_1}`}>{pos.place} · {pos.shift} · {pos.type} Level<br></br>
                {pos.salary}<br></br> 
            </p>
            <p className={`${PositionCSS.overview}`}> 
                {pos.overview}
            </p>
            <h5 className={`${PositionCSS.skills}`}>Required skills</h5>
              <p className={`${PositionCSS.p_2}`}>
                {skills.map((skill) => {
                  return (
                    <span className={`${PositionCSS.skill}`}>{skill} · </span> 
                  );
                })}
              </p>
            <Link to={`/application/${id}`} className={PositionCSS.navigationContact}>
              <button  className={`${PositionCSS.contact}`}>
                JOIN US
              </button>
            </Link>
            </LatoFont>
          </div>   
    </div>
  );
}

export default Position;