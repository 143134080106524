import React from "react";
import { useState } from "react";
import WebCSS from "./Web.module.css";
import {RobotoFont, LatoFont} from '../../fonts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

function Ui() {
  const[one, setOne]=useState(false);
  const[two, setTwo]=useState(false);
  const[three, setThree]=useState(false);
  const[four, setFour]=useState(false);
  const[five, setFive]=useState(false);
  const[six, setSix]=useState(false);
  const[seven, setSeven]=useState(false);

  function showText(figure){
    switch(figure) {
      case 1:
        if(one===false){
          setOne(true);
        }
        else{
          setOne(false)
        };
        break;
      case 2:
        if(two===false){
          setTwo(true);
        }
        else{
          setTwo(false)
        };
        break;
      case 3:
        if(three===false){
          setThree(true);
        }
        else{
          setThree(false)
        };
        break;
      case 4:
        if(four===false){
          setFour(true);
        }
        else{
          setFour(false)
        };
        break;
      case 5:
        if(five===false){
          setFive(true);
        }
        else{
          setFive(false)
        };
        break;
      case 6:
        if(six===false){
          setSix(true);
        }
        else{
          setSix(false)
        };
        break;
      case 7:
        if(seven===false){
          setSeven(true);
        }
        else{
          setSeven(false)
        };
        break;
      default:
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(false);
        setSix(false);
        setSeven(false);
    }
  }
  return (
    <>
      <div className={`${WebCSS.container}`} id="ui">
        <div className={`${WebCSS.overview}`}>
          <LatoFont>
            <p className={`${WebCSS.text}`}>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(1)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${one? WebCSS.signOpen : WebCSS.sign}`}/> User Research
              </a><br></br>
              <p className={`${one? WebCSS.subText : WebCSS.subTextNo}`}>
                  Conducting user interviews, surveys, and usability testing to understand the needs, 
                  preferences, and behaviors of the target audience.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(2)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${two? WebCSS.signOpen : WebCSS.sign}`}/> Information Architecture
              </a><br></br>
              <p className={`${two? WebCSS.subText : WebCSS.subTextNo}`}>
                  Organizing and structuring content, features, and functionalities to create 
                  an intuitive and user-friendly navigation system.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(3)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${three? WebCSS.signOpen : WebCSS.sign}`}/> Wireframing
              </a><br></br>
              <p className={`${three? WebCSS.subText : WebCSS.subTextNo}`}>
                  Creating low-fidelity sketches or digital representations that outline the basic 
                  layout and structure of the application or website.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(4)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${four? WebCSS.signOpen : WebCSS.sign}`}/> Prototyping
              </a><br></br>
              <p className={`${four? WebCSS.subText : WebCSS.subTextNo}`}>
                  Developing interactive, clickable prototypes that simulate the user flow and functionality of the final product.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(5)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${five? WebCSS.signOpen : WebCSS.sign}`}/> Visual Design
              </a><br></br>
              <p className={`${five? WebCSS.subText : WebCSS.subTextNo}`}>
                  Creating the visual elements of the interface, including color schemes, typography, iconography, and imagery.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(6)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${six? WebCSS.signOpen : WebCSS.sign}`}/> Iconography and Visual Assets
              </a><br></br>
              <p className={`${six? WebCSS.subText : WebCSS.subTextNo}`}>
                  Creating custom icons, illustrations, and graphics that complement the overall design.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(7)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${seven? WebCSS.signOpen : WebCSS.sign}`}/> Final Design Deliverables
              </a><br></br>
              <p className={`${seven? WebCSS.subText : WebCSS.subTextNo}`}>
                  Providing high-fidelity mockups, style guides, and design assets for development.
              </p>
            </p>
          </LatoFont>
        </div>
        <div className={`${WebCSS.titleContainer}`}>
          <RobotoFont>
            <p className={`${WebCSS.title}`}>UX / UI Design</p>
          </RobotoFont>
        </div>
      </div>
    </>
  );
}

export default Ui;