import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OfflineCSS from "./Offline.module.css";
import Wifi from "../images/home/offline.png";

function Offline() {

  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.onLine) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className={`${OfflineCSS.general}`}>
        <img src={Wifi} alt="offline" className={`${OfflineCSS.image}`}/>
        <br></br><br></br>

        You are offline!
      </div>
    </>
  );
}

export default Offline;