import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectWithDelay = ({ to, delay }) => {
    const navigate = useNavigate();
  
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        navigate(to);
      }, delay);
  
      // Cleanup the timeout in case the component unmounts
      return () => clearTimeout(timeoutId);
    }, [navigate, to, delay]);
  return (
    <div>
    </div>
  );
};

export default RedirectWithDelay;