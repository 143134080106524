
import { useNavigate, Link } from "react-router-dom";
import Veleax from "../../images/logo/veleax_orange.png";
import Login from "../../images/login/47cf8c1fa527b05c3687ec23a053aab6.png";
import Menu from "../../images/home/menu.png";
import NavCSS from "./Navbar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect, useRef } from "react";
import http from "../../http-common";
import Cookies from 'js-cookie';
import { LatoFont } from "../../fonts";
import PopupForm from '../home/PopupForm';
import { useUser } from '../Auth/userContext';

function Navbar() {
  const [user, setUser] = useState(Cookies.get('user'));
  const [userLogged, setUserLogged] = useState({});
  const navigate = useNavigate(); 
  const [navOpen, setNavOpen] = useState(false);
  const [careerOpen, setCareerOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { updateUser } = useUser();
  const collapsibleRef = useRef(null);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  }
  
  useEffect(() => {
    function handleOutsideClick(event) {
      if (collapsibleRef.current && !collapsibleRef.current.contains(event.target)) {
        collapseNav();
      }
    }
    // Add event listeners when component mounts
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    if(user)
    {
      const fetchUser = async () => {
        try {
          const token = JSON.parse(user).token;
  
          if (!token) {
            return;
          }
  
          const response = await http.get('users/user', {
            headers: {
              Authorization: token,
            },
          });
  
          const id = response.data.id;
          http.get(`/users/id/${id}`).then((res) =>{
            setUserLogged(res.data);
            updateUser(
            {
                id: id,
                email: res.data.email,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                photo: res.data.photo,
                cv: res.data.cv
            });
          });
        } catch (error) {
          console.error('Error fetching user details:', error.response.data.message);
        }
      };
      if (!navigator.onLine) {
        navigate("/offline");
      }
      else{
        fetchUser();
      }
    }
    // Remove event listeners when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [collapsibleRef]);
  
  function logOut(){
    toggleNav();
    if (!navigator.onLine) {
      Cookies.remove('user');
      Cookies.remove('redirect');
      sessionStorage.removeItem('user');
      localStorage.removeItem('persist:root');
      setUser(null);
      setUserLogged(null);
      navigate("/offline");
    }
    else{
      http.get("/users/login").then((response) =>{
        Cookies.remove('user');
        Cookies.remove('redirect');
        sessionStorage.removeItem('user');
        localStorage.removeItem('persist:root');
        setUser(null);
        setUserLogged(null);
      });
      navigate("/");
    }
  }
  function toggleNav() {
    setNavOpen((state) => !state);
    setCareerOpen(false);
    setProfileOpen(false);
  }
  function collapseNav() {
    setNavOpen(false);
    setCareerOpen(false);
    setProfileOpen(false);
  }
  function trueCareer() {
    setCareerOpen(true);
  }
  function falseCareer() {
    setCareerOpen(false);
  }
  function trueProfile() {
    setProfileOpen(true);
  }
  function falseProfile() {
    setProfileOpen(false);
  }

  return (
    <div>
      <LatoFont>
        <nav className={`navbar navbar-expand-xxl border-body fixed-top`} style={{backgroundColor:"#191b23"}}>
            <div className={`container-fluid`}>
                <Link to="/" className="navbar-brand"><img src={Veleax} alt="logo" height="100" className={`${NavCSS.navLogo}`}/></Link>
                <button onClick={toggleNav} className={`${navOpen ? "navbar-toggler" : "navbar-toggler collapsed"} ${NavCSS.color} ${NavCSS.toggler}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" 
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <img src={Menu} alt="menu" className={`${NavCSS.menu}`}/>
                    {/*<FontAwesomeIcon icon={faBars} size="xl" style={{color: "rgb(229, 227, 227)"}}/>*/}
                </button>
              <div ref={collapsibleRef} className={`${navOpen ? "collapse navbar-collapse show" : "collapse navbar-collapse"} ${NavCSS.navbarBar}`} id="navbarNavDropdown">
                <ul className={`navbar-nav me-auto`}>
                    <li className={`nav-item`}>
                      <Link onClick={collapseNav} to="/services" className={`nav-link ${NavCSS.navigation} ${NavCSS.links}`}>SERVICES</Link>
                    </li>
                    <li className={`nav-item`}>
                      <Link onClick={collapseNav} to="/research" className={`nav-link ${NavCSS.navigation} ${NavCSS.links}`}>RESEARCH</Link>
                    </li>            
                    <li className={`nav-item dropdown`} onMouseLeave={falseCareer}>
                        <Link to="/" className={`nav-link ${NavCSS.navigation} ${NavCSS.links}`} onMouseOver={trueCareer}
                          role="button" data-bs-toggle="dropdown" aria-expanded="false">CAREERS <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${NavCSS.chevronRight}`}/>
                        </Link>
                        <ul className={`dropdown-menu ${careerOpen ? "show": "collapse"}`} style={{backgroundColor:"#191b23"}}>
                          <li>
                            <Link onClick={collapseNav} to="/positions" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>POSITIONS</Link>
                          </li>
                          <li>
                            <Link onClick={collapseNav} to="/trainings" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>TRAINING</Link>
                          </li>
                        </ul>
                    </li>
                    <li className={`nav-item`}>
                        <Link onClick={collapseNav} to="/about" className={`nav-link ${NavCSS.navigation} ${NavCSS.links}`}>ABOUT</Link>
                    </li>
                    <li className={`nav-item dropdown ${NavCSS.hover}`} onMouseLeave={falseProfile}>
                      <Link to="/" className={`nav-link ${NavCSS.navigation} ${NavCSS.links}`} onMouseOver={trueProfile}
                          role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {!user ? (<img src={Login} alt="login" className={`${NavCSS.signInPhotoB}`}/> ):
                               (<img src={`${process.env.REACT_APP_AWS_URL}/${userLogged.photo}`} className={NavCSS.signInPhoto} alt="profile"/>)} <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${NavCSS.chevronRight}`}/>
                      </Link>
                    
                      <ul className={`dropdown-menu ${profileOpen ? "show": "collapse"}`} style={{backgroundColor:"#191b23"}}>
                      {!user ? 
                      (
                        <>
                        <li>
                          <Link onClick={collapseNav} to="/login" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>LOGIN</Link>
                        </li>
                        <li>
                          <Link onClick={collapseNav} to="/signup" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>SIGN UP</Link>
                        </li>
                        </>
                      ):
                      (
                        ((userLogged.role==='admin') && (
                          <>
                            <li>
                              <Link onClick={collapseNav} to="/profile" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>
                              PROFILE<br></br>
                                <span className={`${NavCSS.email}`}>{userLogged.email}</span>
                              </Link>
                            </li>
                            <li>
                              <Link onClick={collapseNav} to="/admin" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>ADMIN PANEL</Link>
                            </li>
                            <li><hr className={`dropdown-divider" ${NavCSS.hr}`}></hr></li>
                            <li>
                              <div className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`} 
                                onClick={() => logOut()}>LOG OUT</div>
                            </li>
                          </>
                        )) ||
                        ((userLogged.role==='hr') && (
                          <>
                            <li>
                              <Link onClick={collapseNav} to="/profile" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>
                              PROFILE<br></br>
                                <span className={`${NavCSS.email}`}>{userLogged.email}</span>
                              </Link>
                            </li>
                            <li>
                              <Link onClick={collapseNav} to="/hr" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>
                                HR PANEL
                              </Link>
                            </li>
                            <li><hr className={`dropdown-divider" ${NavCSS.hr}`}></hr></li>
                            <li>
                              <div className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`} 
                                onClick={() => logOut()}>LOG OUT</div>
                            </li>
                          </>
                        )) ||
                        ((userLogged.role!=='admin' && userLogged.role!=='hr') && (
                          <>
                            <li>
                              <Link onClick={collapseNav} to="/profile" className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`}>
                              PROFILE<br></br>
                                <span className={`${NavCSS.email}`}>{userLogged.email}</span>
                              </Link>
                            </li>
                            <li><hr className={`dropdown-divider" ${NavCSS.hr}`}></hr></li>
                            <li>
                              <div className={`dropdown-item ${NavCSS.navigationD} ${NavCSS.links} ${NavCSS.dropdown}`} 
                                onClick={() => logOut()}>LOG OUT</div>
                            </li>
                          </>
                        ))
                      )}  
                      </ul>
                    </li>
                    <li className={`nav-item`}>
                       <button className={NavCSS.contact} onClick={togglePopup}>
                        <Link className={`nav-link ${NavCSS.navigationContact} ${NavCSS.links}`}>CONTACT</Link>
                      </button>
                      <PopupForm show={showPopup} handleClose={togglePopup}/>
                    </li>
                </ul>
              </div>
            </div>
        </nav>
        </LatoFont>
    </div>
  );
}

export default Navbar;
