import React from "react";
import { useEffect } from "react";
import AboutCSS from "./About.module.css";
import Icon1 from "../images/about/about1.png";
import Icon2 from "../images/about/about2.png";
import { LatoFont, RobotoFont } from "../fonts";

function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <>
    <div className={`${AboutCSS.devizBack}`}>
      <RobotoFont>
            <p className={`${AboutCSS.heading}`}>
              We Create World - <span className={`${AboutCSS.classWord}`}>CLASS</span><br></br>
              Web Applications
            </p>
            {/*We are Dedicated to Producing<br></br>
              Software Experiences that Exemplify<br></br>
  Quality of World - <span className={`${AboutCSS.classWord}`}>CLASS</span>*/}
      </RobotoFont>
    </div>
    <div className={`${AboutCSS.wrapper1}`}>
      <div className={`${AboutCSS.container1}`}>
        <div className={`${AboutCSS.subContainer1}`}>
          <RobotoFont>
            <p className={`${AboutCSS.smallHeading1}`}>Expertise and Specializations</p>
          </RobotoFont>
          <LatoFont>
            <p className={`${AboutCSS.p1}`}>
                Our professional development teams encompass a wide spectrum of expertise, spanning across various domains 
                such as web development, UX/UI design, custom application development, branding, corporate training, and more. This diverse 
                skill set allows us to take on projects of varying complexity and scale, ensuring that we deliver tailored 
                solutions that precisely address our clients' unique needs.
            </p>
          </LatoFont>
        </div>
      </div>
    </div>
    <div className={`${AboutCSS.wrapper2}`}>
          <div className={`${AboutCSS.aboutImgContainer}`}>
            <img src={Icon1} alt="about1" className={`${AboutCSS.aboutImg}`}></img>
          </div>
          <div className={`${AboutCSS.container2}`}>
            <div className={`${AboutCSS.subContainer2}`}>
              <RobotoFont>
                <p className={`${AboutCSS.smallHeading2}`}>Collaborative Approach</p>
              </RobotoFont>
              <LatoFont>
                <p className={`${AboutCSS.p2}`}>
                  At VELEAX, collaboration is at the heart of everything we do. Our development teams work in seamless harmony 
                  with each other, fostering an environment that encourages creativity and knowledge-sharing. This collaborative 
                  ethos extends beyond our internal teams to our clients, ensuring that we work hand-in-hand to achieve 
                  the desired outcomes.
                </p>
              </LatoFont>
            </div>
          </div>
    </div>
    <div className={`${AboutCSS.wrapper1}`}>
      <div className={`${AboutCSS.container1}`}>
        <div className={`${AboutCSS.subContainer1}`}>
          <RobotoFont>
            <p className={`${AboutCSS.smallHeading1}`}>Innovation and Adaptability</p>
          </RobotoFont>
          <LatoFont>
            <p className={`${AboutCSS.p1}`}>
                Staying at the forefront of technological advancements is a cornerstone of our approach. Our professional 
                development teams are adept at leveraging cutting-edge technologies and methodologies, ensuring that our 
                solutions are not only current but also future-proofed. This forward-thinking mindset empowers us to adapt 
                swiftly to evolving industry trends and emerging technologies.
            </p>
          </LatoFont>
        </div>
      </div>
    </div>
    <div className={`${AboutCSS.wrapper2}`}>
          <div className={`${AboutCSS.aboutImgContainer}`}>
            <img src={Icon2} alt="about2" className={`${AboutCSS.aboutImg}`}></img>
          </div>
          <div className={`${AboutCSS.container2}`}>
            <div className={`${AboutCSS.subContainer2}`}>
              <RobotoFont>
                <p className={`${AboutCSS.smallHeading2}`}>Quality assurance</p>
              </RobotoFont>
              <LatoFont>
                <p className={`${AboutCSS.p2}`}>
                  Quality is non-negotiable for us. Our development teams adhere to rigorous testing and quality assurance 
                  protocols, guaranteeing that every deliverable is of the highest standard. Furthermore, we believe in a 
                  culture of continuous improvement, where feedback loops and retrospectives are integral components of 
                  our project lifecycle.
                </p>
              </LatoFont>
            </div>
          </div>
    </div>
    <div className={`${AboutCSS.wrapper1}`}>
      <div className={`${AboutCSS.container1}`}>
        <div className={`${AboutCSS.subContainer1}`}>
          <RobotoFont>
            <p className={`${AboutCSS.smallHeading1}`}>Client-Centric Solutions</p>
          </RobotoFont>
          <LatoFont>
            <p className={`${AboutCSS.p1}`}>
                We understand that every client is unique, with their own set of challenges and goals. Our professional 
                development teams approach each project with a client-centric mindset, ensuring that we not only meet but 
                exceed expectations. This client-focused approach has been instrumental in establishing long-lasting 
                partnerships and a track record of successful project deliveries.
            </p>
          </LatoFont>
        </div>
      </div>
    </div>
    </>
  );
}

export default About;