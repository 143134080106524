import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Margin from "../components/margin/Margin";
import Footer from "../components/footer/Footer";

function MainLayout() {
  return (
    <div>
      <Margin/>
      <Navbar />
      <Outlet/>
      <Footer />
    </div>
  );
}

export default MainLayout;
