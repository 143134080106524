import React from "react";
import ChooseCSS from "./Choose.module.css";
import {RobotoFont, LatoFont} from '../../fonts';
import { Link } from "react-router-dom";

function Choose() {

  return (
    <>
      <div className={`${ChooseCSS.flexContainer}`}>
        <div className={`${ChooseCSS.whyBlock}`}>
            <RobotoFont>
                    <h1 className={`${ChooseCSS.heading}`}>
                        Why choose <span className={`${ChooseCSS.why}`}>VELEAX</span>?
                    </h1>
            </RobotoFont>
            <LatoFont>
                    <p className={`${ChooseCSS.justify}`}>
                        Our developers are highly skilled and experienced professionals 
                        who are dedicated to delivering top quality web applications tailored to 
                        meet your specific needs.
                    </p>
            </LatoFont><br></br>
            <button className={ChooseCSS.contact}>
                <Link to="/about" className={`nav-link ${ChooseCSS.navigationContact}`}>LEARN MORE</Link>
            </button>
        </div>
        <div className={`${ChooseCSS.imgBlock}`}>
        </div>
      </div>
    </>
  );
}

export default Choose;