import MarginCSS from "./Margin.module.css";

function Margin() {
  return (
    <div>
        <div className={`${MarginCSS.backgroundMargin}`}>
        </div>
    </div>
  );
}

export default Margin;
