// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Margin_backgroundMargin__MpAgA{
    background-color: #000002;
    padding:0;
    margin:0;
    height: 136px;
    width:100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/margin/Margin.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,SAAS;IACT,QAAQ;IACR,aAAa;IACb,UAAU;AACd","sourcesContent":[".backgroundMargin{\n    background-color: #000002;\n    padding:0;\n    margin:0;\n    height: 136px;\n    width:100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundMargin": `Margin_backgroundMargin__MpAgA`
};
export default ___CSS_LOADER_EXPORT___;
