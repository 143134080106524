import React from "react";
import { useState, useEffect } from "react";
import ResearchCSS from "./Research.module.css";
import {LatoFont, RobotoFont} from '../fonts';
import { Link } from "react-router-dom";
  
function Research() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handlePayPalPayment = () => {
    // URL of your PayPal payment page
    const payPalUrl = 'https://www.paypal.com/donate/?hosted_button_id=WFG26TVBH59QU'; 
    
    // Calculate the center position for the popup window
    const width = 340; // Width of the popup window
    const height = 400; // Height of the popup window
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    
    // Open PayPal payment page in a popup window
    const newWindow = window.open(
      payPalUrl,
      'PayPal Payment',
      `width=${width},height=${height},left=${left},top=${top}`
    );
    
    // Focus on the new window if it's already opened
    if (newWindow) {
      newWindow.focus();
    } else {
      // Handle popup blocker or other issues
      alert('Popup blocked. Please enable popups for this website.');
    }
  };

  return (
    <>
    <div className={`${ResearchCSS.devizBack}`}>
      <RobotoFont>
            <p className={`${ResearchCSS.heading}`}>
              AI &nbsp; Horizons:<br></br>
              Pioneering Tomorrow's Solutions
            </p>
      </RobotoFont>
    </div>
    <div className={`${ResearchCSS.wrapper1}`}>
      <div className={`${ResearchCSS.container1}`}>
        <div className={`${ResearchCSS.subContainer1}`}>
          <RobotoFont>
            <p className={`${ResearchCSS.smallHeading1}`}>Research Areas</p>
          </RobotoFont>
          <LatoFont>
            <p className={`${ResearchCSS.p1}`}>
            Our company is actively involved in two major areas of computer science research. 
            </p>
          <p>
            <button className={ResearchCSS.contact} onClick={handlePayPalPayment}>
                DONATE
            </button>
          </p>
          </LatoFont>
        </div>
      </div>
    </div>
    <div className={`${ResearchCSS.wrapper2}`}>
          <div className={`${ResearchCSS.container2}`}>
            <div className={`${ResearchCSS.subContainer2}`}>
              <RobotoFont>
                <p className={`${ResearchCSS.smallHeading2}`}>Data Management</p>
              </RobotoFont>
              <LatoFont>
                <p className={`${ResearchCSS.p2}`}>
                  Data Management research spans multiple disciplines, incorporating elements from theory, 
                  machine learning, operating systems, programming languages, information retrieval, 
                  and various other fields. Key research areas within this domain center around:
                  <br></br><br></br>
                  <ol>
                    <li>Developing algorithms and system architectures to efficiently handle large-scale data volumes</li>
                    <li>Extending the functionality of databases to support complex operations demanded by modern applications</li>
                    <li>Utilizing computational models to analyze textual data and discern user information needs</li>
                    <li>Ensuring proper data management and access within contemporary data-sharing platforms</li>
                    <li>Advocating for societal values such as privacy, diversity, and fairness within data-driven applications</li>
                  </ol>
                  <br></br>
                  These research problems are crucial for advancing the field of data management 
                  and addressing the complex challenges posed by the growing volume, variety, and velocity 
                  of data in today's digital world.
                </p>
              </LatoFont>
            </div>
          </div>
    </div>
    <div className={`${ResearchCSS.wrapper3}`}>
      <div className={`${ResearchCSS.container1}`}>
      </div>
    </div>
    <div className={`${ResearchCSS.wrapper2}`}>
          <div className={`${ResearchCSS.container2}`}>
            <div className={`${ResearchCSS.subContainer2}`}>
              <RobotoFont>
                <p className={`${ResearchCSS.smallHeading2}`}>Artificial Intelligence</p>
              </RobotoFont>
              <LatoFont>
                <p className={`${ResearchCSS.p2}`}>
                Advancing the forefront of computing, Artificial Intelligence (AI) research 
                empowers machines to address complex problems, execute intricate tasks autonomously, 
                acquire knowledge from experience, collaborate seamlessly with humans, and navigate 
                uncertainty effectively. <br></br><br></br>
                
                Current AI research spans various fields such as automated planning, 
                autonomous systems, computational neuroscience, computer vision, heuristic search, intelligent 
                tutoring systems, knowledge discovery, machine learning, multi-agent systems, natural language processing, 
                probabilistic modeling, reinforcement learning, robotics, search engines, and sequential decision making.
                </p>
              </LatoFont>
            </div>
          </div>
    </div>
    <div className={`${ResearchCSS.wrapper3}`}>
      <div className={`${ResearchCSS.container1}`}>
      </div>
    </div>
    </>
  );
}

export default Research;