import React, { useState } from "react";
import CustomCSS from "./Custom.module.css";
import {RobotoFont, LatoFont} from '../../fonts';

function Custom() {

  const [showScrollingDiv, setShowScrollingDiv] = useState(false);

  const handleScroll = () => {
      const contentElement = document.getElementById('content');
      const contentRect = contentElement.getBoundingClientRect();

      if (contentRect.left <= 0) {
        setShowScrollingDiv(true);
      } else {
        setShowScrollingDiv(false);
      }
  };
  return (
    <>
      <div className={`${CustomCSS.flexContainer}`} id="custom" onMouseOver={handleScroll} onTouchStart={handleScroll}>
        <div className={showScrollingDiv ? `${CustomCSS.whyBlock}`: `${CustomCSS.whyBlock1}`} id="content">
            <RobotoFont>
                    <p className={`${CustomCSS.heading}`}>
                        Custom App Development
                    </p>
            </RobotoFont>
            <LatoFont>
                    <p className={`${CustomCSS.justify}`}>
                        Custom app development involves writing unique 
                        code and designing a solution from scratch to address the unique challenges 
                        and objectives of the project. 
                        Our skilled team is prepared to transform your vision into a tangible reality.
                    </p>
            </LatoFont><br></br>
        </div>
        <div className={`${CustomCSS.imgBlock}`}>
        </div>
      </div>
    </>
  );
}

export default Custom;