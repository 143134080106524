import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import http from "../http-common";
import Cookies from 'js-cookie';
import ProfileCV from "../components/profile/ProfileCV";
import RedirectWithDelay from '../components/Auth/redirect';


function ProfileCVUpload() {
  const user = Cookies.get('user');

  return (
    <>
      <div>
        {!user ?
        (
          <h2>No user logged in</h2>
        ):
        (
          <>
            {!navigator.onLine?
            (
              <RedirectWithDelay to="/offline" delay={0}/>
            ):
            (
              <ProfileCV></ProfileCV>
            )
            }
          </>
        )}
      </div>
    </>
  );
}

export default ProfileCVUpload;