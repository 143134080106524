import React from "react";
import { useState } from "react";
import WebCSS from "./Web.module.css";
import {RobotoFont, LatoFont} from '../../fonts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

function Branding() {
  const[one, setOne]=useState(false);
  const[two, setTwo]=useState(false);
  const[three, setThree]=useState(false);
  const[four, setFour]=useState(false);
  const[five, setFive]=useState(false);
  const[six, setSix]=useState(false);
  const[seven, setSeven]=useState(false);

  function showText(figure){
    switch(figure) {
      case 1:
        if(one===false){
          setOne(true);
        }
        else{
          setOne(false)
        };
        break;
      case 2:
        if(two===false){
          setTwo(true);
        }
        else{
          setTwo(false)
        };
        break;
      case 3:
        if(three===false){
          setThree(true);
        }
        else{
          setThree(false)
        };
        break;
      case 4:
        if(four===false){
          setFour(true);
        }
        else{
          setFour(false)
        };
        break;
      case 5:
        if(five===false){
          setFive(true);
        }
        else{
          setFive(false)
        };
        break;
      case 6:
        if(six===false){
          setSix(true);
        }
        else{
          setSix(false)
        };
        break;
      case 7:
        if(seven===false){
          setSeven(true);
        }
        else{
          setSeven(false)
        };
        break;
      default:
        setOne(false);
        setTwo(false);
        setThree(false);
        setFour(false);
        setFive(false);
        setSix(false);
        setSeven(false);
    }
  }

  return (
    <>
      <div className={`${WebCSS.container}`} id="branding">
        <div className={`${WebCSS.titleContainer}`}>
          <RobotoFont>
            <p className={`${WebCSS.title}`}>Branding</p>
          </RobotoFont>
        </div>
        <div className={`${WebCSS.overview}`}>
        <LatoFont>
            <p className={`${WebCSS.text}`}>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(1)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${one? WebCSS.signOpen : WebCSS.sign}`}/> Brand Strategy Development
              </a><br></br>
              <p className={`${one? WebCSS.subText : WebCSS.subTextNo}`}>
                  Defining the brand's mission, vision, values, and positioning in the market. 
                  Conducting market research and competitor analysis to identify unique selling points.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(2)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${two? WebCSS.signOpen : WebCSS.sign}`}/> Brand Naming and Tagline Creation
              </a><br></br>
              <p className={`${two? WebCSS.subText : WebCSS.subTextNo}`}>
                  Crafting a name that reflects the brand's essence, values, and offerings. 
                  Developing a memorable and meaningful tagline that encapsulates the brand's promise.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(3)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${three? WebCSS.signOpen : WebCSS.sign}`}/> Brand Guidelines
              </a><br></br>
              <p className={`${three? WebCSS.subText : WebCSS.subTextNo}`}>
                  Documenting the brand's visual and verbal guidelines in a comprehensive style guide. 
                  Outlining rules for logo usage, color schemes, typography, and tone of voice.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(4)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${four? WebCSS.signOpen : WebCSS.sign}`}/> Brand Collateral Design
              </a><br></br>
              <p className={`${four? WebCSS.subText : WebCSS.subTextNo}`}>
                  Designing promotional materials such as brochures, flyers, posters, and packaging that align with the brand identity.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(5)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${five? WebCSS.signOpen : WebCSS.sign}`}/> Website and Digital Presence
              </a><br></br>
              <p className={`${five? WebCSS.subText : WebCSS.subTextNo}`}>
                  Designing and developing a website that reflects the brand's identity and provides a seamless user experience. 
                  Ensuring consistent branding across social media profiles, email marketing, and digital advertising.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(6)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${six? WebCSS.signOpen : WebCSS.sign}`}/> Content Strategy
              </a><br></br>
              <p className={`${six? WebCSS.subText : WebCSS.subTextNo}`}>
                  Developing a content plan that aligns with the brand's messaging and resonates with the target audience. 
                  Creating a content calendar for blog posts, social media updates, and other marketing materials.
              </p>
              <a className={`${WebCSS.textLink}`} onClick={()=>showText(7)}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" className={`${seven? WebCSS.signOpen : WebCSS.sign}`}/> Brand Management and Maintenance
              </a><br></br>
              <p className={`${seven? WebCSS.subText : WebCSS.subTextNo}`}>
                  Providing ongoing support and guidance to ensure that the brand identity remains consistent and effective over time.
              </p>
            </p>
          </LatoFont>
        </div>
      </div>
    </>
  );
}

export default Branding;