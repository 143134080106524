import React, { useState } from "react";
import Logincss from "./login.module.css";
import http from "../../http-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { LatoFont, RobotoFont } from "../../fonts";
import RedirectWithDelay from './redirect';
import { useUser } from '../Auth/userContext';
import { useNavigate } from "react-router-dom";


function ChangePassword() {
    const { user } = useUser();
    const [formData, setFormData] = useState({
        id: user.id,
        oldPassword: '',
        newPassword: ''
    });
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [confirm, setConfirm] = useState(false);
    const navigate = useNavigate();

    const handleChangeOldPassword = (e) => {
        setFormData({
          ...formData,
          oldPassword: e.target.value
        });
    };
    const handleChangeNewPassword = (e) => {
        setFormData({
          ...formData,
          newPassword: e.target.value
        });
        setIsPasswordValid(passwordRegex.test(e.target.value));
        setError("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          if (!navigator.onLine) {
            navigate("/offline");
          }
          else{
            await http.post('users/changePassword', formData).then((response) =>{
                if(response.data.error){
                  setError(response.data.error);
                  setFormData({
                      ...formData,
                      oldPassword: '',
                      newPassword: ''
                    });
                }
                else{
                  setSuccess(response.data.message);
                  setError('');
                  setFormData({
                      ...formData,
                      oldPassword: '',
                      newPassword: ''
                    });
                  setIsPasswordValid(false);
                  setConfirm(true);
                } 
            });
          }
        } catch (error) {
          setSuccess("");
          setFormData({
            ...formData,
            oldPassword: '',
            newPassword: ''
          });
          setError("failed to change password");
        }
      };

  return (
    <div className={`${Logincss.modal}`}>
        <div className={`${Logincss.divSection2}`} style={{textAlign: "center", padding: "50px 20px 0px 20px"}}>
        <section className={`${Logincss.section2}`}>
          {(confirm===false)?
          (
            <form onSubmit={handleSubmit} name="changePassword">
            <LatoFont>
            <input
                  className={`${Logincss.input}`}
                  type="password"
                  name="oldPassword"
                  value={formData.oldPassword}
                  maxLength="100"
                  onChange={handleChangeOldPassword}
                  placeholder="OLD PASSWORD"
              /><br></br>
            <input
                  className={isPasswordValid===false? `${Logincss.inputError}`: `${Logincss.input}`}
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  maxLength="100"
                  onChange={handleChangeNewPassword}
                  placeholder="NEW PASSWORD"
            /><br></br>
              </LatoFont>
            <RobotoFont>
              <div className={`${Logincss.error}`} style={{textAlign: "center", margin:"0px"}}>
              {(isPasswordValid===false)?
              (
                <p>* (6-20) characters, at least one UpperCase, one LowerCase and one Number</p>
              ):
              (
                <p>{error}<span style={{color: "#191b23"}}>.</span></p>
              )}
                  
              </div>
            </RobotoFont>
            <LatoFont>
              <div className={`${Logincss.buttonSendDiv}`} style={{textAlign: "center", marginTop:"40px", padding:"0px"}}>
                {(isPasswordValid===false || formData.oldPassword==='' || formData.newPassword==='')? 
                (
                  <button disabled className={`${Logincss.buttonDisabled}`} style={{textAlign: "center", margin:"0px"}} type="submit">UPDATE PASSWORD</button>
                ):
                (
                  <button className={`${Logincss.button}`} style={{textAlign: "center", margin:"0px"}} type="submit">UPDATE PASSWORD</button>
                )}
                <p className={`${Logincss.signUp}`} style={{textAlign:"center", padding:"20px 0px 0px 0px", margin:"0px 0px 0px 0px"}} >
                    <a href="/reset">Reset password</a>
                </p>
              </div>
            </LatoFont>
        </form>
        ):(
            <LatoFont>
            <div className={`${Logincss.buttonSendDiv1}`}>
              <FontAwesomeIcon icon={faCheck} className={`${Logincss.v}`} size="xl"/>
              <p className={`${Logincss.login}`}>Password update successful</p>
              <RedirectWithDelay to="/profile" delay={2000} />
            </div>
            </LatoFont>
          )}
        </section>
      </div>     
    </div>
  );
}

export default ChangePassword;