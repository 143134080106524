import React, { useState, useEffect } from "react";
import Logincss from "./login.module.css";
import SignupCSS from "./signup.module.css"
import http from "../../http-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { LatoFont, RobotoFont } from "../../fonts";
import RedirectWithDelay from './redirect';
import { useNavigate } from "react-router-dom";


function SignUp() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const firstNameRegex = /^[a-zA-Z ]+$/;
    const lastNameRegex = /^[a-zA-Z ]+$/;
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [error, setError] = useState("");
    const [confirm, setConfirm] = useState(false);
    const [eye, setEye] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    }, []);

    const handleChangeEmail = (e) => {
        setFormData({
          ...formData,
          email: e.target.value
        });
        setIsEmailValid(emailRegex.test(e.target.value));
        setError("");
      };
    
      const handleChangePassword = (e) => {
        setFormData({
          ...formData,
          password: e.target.value
        });
        setIsPasswordValid(passwordRegex.test(e.target.value));
        setError("");
        setEye(false);
      };

      function toggleEye(){
        if(eye===false){
          setEye(true);
        }
        else{
          setEye(false);
        }
      }

      const handleChangeFirstName = (e) => {
        setFormData({
          ...formData,
          firstName: e.target.value
        });
        setIsFirstNameValid(firstNameRegex.test(e.target.value));
        setError("");
      };

      const handleChangeLastName = (e) => {
        setFormData({
          ...formData,
          lastName: e.target.value
        });
        setIsLastNameValid(lastNameRegex.test(e.target.value));
        setError("");
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          if (!navigator.onLine) {
            navigate("/offline");
          }
          else{
            await http.post('users/register', formData).then((res) =>{
                if(res.data.error){
                  console.log(res.data.error);
                  setError(res.data.error);
                }
                else{
                  setError('');
                  setFormData({
                      firstName: '',
                      lastName: '',
                      email: '',
                      password: ''
                  });
                  setIsEmailValid(false);
                  setIsPasswordValid(false);
                  setIsFirstNameValid(false);
                  setIsLastNameValid(false);
                  setConfirm(true);
                } 
            });
          }
        } catch (error) {
          setError(error.message);
        }
      };
  return (
    <div className={`${Logincss.modal}`}>
        <div className={`${Logincss.divSection2}`} style={{textAlign: "center", padding: "0px 20px 0px 20px"}}>
        <section className={`${Logincss.section2}`}>
          {(confirm===false)?
          (
            <form onSubmit={handleSubmit} name="reset">
            <LatoFont>
              <input
                    className={isFirstNameValid===false? `${Logincss.inputError}`: `${Logincss.input}`}
                    name="firstName"
                    placeholder="FIRST NAME*"
                    maxLength="100"
                    value={formData.firstName}
                    onChange={handleChangeFirstName}
              /><br></br>
              <input
                    className={isLastNameValid===false? `${Logincss.inputError}`: `${Logincss.input}`}
                    name="lastName"
                    placeholder="LAST NAME*"
                    maxLength="100"
                    value={formData.lastName}
                    onChange={handleChangeLastName}
              /><br></br>
              <input
                  className={isEmailValid===false? `${Logincss.inputError}`: `${Logincss.input}`}
                  name="email"
                  placeholder="EMAIL*"
                  maxLength="100"
                  value={formData.email}
                  onChange={handleChangeEmail}
              /><br></br>
              <div className={`${SignupCSS.password}`}>
                <input
                    className={isPasswordValid===false? `${Logincss.inputError}`: `${Logincss.input}`}
                    type={(eye===false)? ("password"):("text")}
                    name="password"
                    value={formData.password}
                    maxLength="100"
                    onChange={handleChangePassword}
                    placeholder="PASSWORD*"
                />
                <div className={`${SignupCSS.eyeDiv}`}>
                  {(eye===false)?
                  (
                    <FontAwesomeIcon icon={faEyeSlash} onClick={toggleEye} size="xs" className={`${SignupCSS.eye}`}/>
                  ):
                  (
                    <FontAwesomeIcon icon={faEye} onClick={toggleEye} size="xs" className={`${SignupCSS.eye}`}/>
                  )}
                </div>
              </div>
              {isPasswordValid===false?
              (
                <p style={{color: "rgb(115, 114, 114)", fontSize: "11px"}}>*(6-20), at least: 1 Upper Case, 1 Lower Case, 1 Number</p>
              ):
              (
                <p><span style={{color: "#191b23"}}>.</span></p>
              )}
              </LatoFont>
            <RobotoFont>
              <div className={`${Logincss.error}`} style={{textAlign: "center", margin:"0px", padding:"0px"}}>
              {(isEmailValid===false || isPasswordValid===false || isFirstNameValid===false || isLastNameValid===false)?
              (
                <p>* Please complete all required fields correctly</p>
              ):
              (
                <p>{error}<span style={{color: "#191b23"}}>.</span></p>
              )}
                  
              </div>
            </RobotoFont>
            <LatoFont>
              <div className={`${Logincss.buttonSendDiv}`} style={{textAlign: "center", marginTop:"40px", padding:"0px"}}>
                {(isEmailValid===false || isPasswordValid===false || isFirstNameValid===false || isLastNameValid===false)? 
                (
                  <button disabled className={`${Logincss.buttonDisabled}`} style={{textAlign: "center", margin:"0px"}} type="submit">SIGN UP</button>
                ):
                (
                  <button className={`${Logincss.button}`} style={{textAlign: "center", margin:"0px"}} type="submit">SIGN UP</button>
                )}
              </div>
            </LatoFont>
        </form>
        ):(
            <LatoFont>
            <div className={`${Logincss.buttonSendDiv1}`}>
              <FontAwesomeIcon icon={faCheck} className={`${Logincss.v}`} size="xl"/>
              <p className={`${Logincss.login}`}>Sign up successful<br></br><br></br>Confirm your email before login</p>
              <RedirectWithDelay to="/login" delay={2000} />
            </div>
            </LatoFont>
          )}
        </section>
      </div>     
    </div>
  );
}

export default SignUp;

